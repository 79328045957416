import React, { useEffect, useState } from "react";
import { getSessionData } from "../../apiServices/clinicianPanelHttpServices/loginHttpService/clinicianLoginHttpService";
import { useParams } from "react-router-dom";
import moment from "moment";

function TelehealthForm() {
  const [upcomingData, setUpcomingData] = useState("");
  let { id, type } = useParams();
  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    const { data } = await getSessionData(id);
    if (!data.error) {
      console.log(data);
      setUpcomingData(data.results.session.user);
    }
  };
  return (
    <>
      <div
        className=""
        style={{ fontFamily: '"Josefin Sans", sans-serif', background: "#fff" }}
      >
        <div className="container pt-5">
          <div className="telehealth-client-main" style={{ padding: 0 }}>
            <div
              className="telehealth-client-top"
              style={{ textAlign: "center", paddingBottom: "30px" }}
            >
              <img
                style={{
                  display: "block",
                  maxWidth: "150px",
                  margin: "0 auto",
                }}
                src="https://passportjourneysapp.com/assets/img/mini-logo.png"
                alt=""
              />
              <h1
                style={{
                  fontSize: "21px",
                  color: "#000",
                  margin: "30px 0 0",
                  fontFamily: '"Josefin Sans", sans-serif',
                }}
              >
                Telehealth Client Consent Form
              </h1>
            </div>
            <div className="content-part">
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                I{" "}
                <span
                  style={{
                    fontWeight: 400,
                    display: "inline-block",
                    width: "20%",
                    borderBottom: "1px solid #3d3d3d",
                  }}
                >
                  {type === "Mother"
                    ? upcomingData?.mother_telehealth_consent?.name
                    : upcomingData?.daughter_telehealth_consent?.name}
                </span>
                hereby give my full consent for myself and/or my minor child to
                receive virtual services with Passport Journeys, LLC as part of
                my/my family’s services. I understand that telehealth is the
                practice of delivering clinical health care services via
                technology assisted media or other electronic means between a
                practitioner and a client who are located in two different
                locations.
              </p>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                I understand the following with respect to telehealth:{" "}
              </p>
              <ul
                style={{
                  listStyle: "decimal-leading-zero",
                  marginBottom: "30px",
                }}
              >
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: 0,
                    }}
                  >
                    I understand that there are risk and consequences associated
                    with telehealth, including but not limited to, disruption of
                    transmission by technology failures, interruption and/or
                    breaches of confidentiality by unauthorized persons, and/or
                    limited ability to respond to emergencies.
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: 0,
                    }}
                  >
                    I understand that there will be no recording of any of the
                    online sessions by either party. All information disclosed
                    within sessions and written records pertaining to those
                    sessions are confidential and may not be disclosed to anyone
                    without written authorization, except where the disclosure
                    is permitted and/or required by law.
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: 0,
                    }}
                  >
                    Iunderstand that the privacy laws that protect the
                    confidentiality of my protected health information (PHI)
                    also apply to telehealth unless an exception to
                    confidentiality applies (i.e. mandatory reporting of child,
                    elder, or vulnerable adult abuse; danger to self or others;
                    or I raise mental/emotional health as an issue in a legal
                    proceeding).
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: 0,
                    }}
                  >
                    I understand that if I am having suicidal or homicidal
                    thoughts, actively experiencing psychotic symptoms, or
                    experiencing a mental health crisis that cannot be resolved
                    remotely, it may be determined that telehealth services are
                    not appropriate and/or a higher level of care is required.
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: 0,
                    }}
                  >
                    I understand that in the event of a technical failure, the
                    following back up plan will be enacted: my clinician will
                    attempt to call me 5 minutes after the technical failure to
                    resume the appointment. If contact is unable to be made, my
                    clinician or a representative of Passport Journeys, LLC will
                    call me to reschedule.
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: 0,
                    }}
                  >
                    I understand that my clinician will make every effort to
                    maintain confidentiality by working from a quiet, secure,
                    and confidential location and it is my responsibility to
                    assure confidentiality by participating from a quiet,
                    secure, and confidential location as well. I understand that
                    the clinician will need to reschedule the session if a
                    quiet, secure, and confidential location is not available.
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: 0,
                    }}
                  >
                    I understand that my clinician may need to contact my
                    emergency contact and/or appropriate authorities in case of
                    an emergency. I understand that my clinician will need to
                    know my location in case of an emergency.
                  </p>
                </li>

                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: 0,
                    }}
                  >
                    I understand that if there are custody orders in place, a
                    copy of the divorce decree must be provided so that the
                    parent's right to consent for treatment can be verified. An
                    emailed copy must be sent to admin@ passportjourneysapp.com
                    before the first appointment.
                  </p>
                </li>
              </ul>
              <h2
                style={{
                  fontSize: "20px",
                  color: "#000",
                  margin: "20px 0 0",
                  fontFamily: '"Josefin Sans", sans-serif',
                }}
              >
                Emergency Protocols:
              </h2>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                I agree to inform the clinician of any changes to my location or
                environment prior to/or at beginning of session. I understand
                that the clinician or Passport Journeys, LLC also needs a
                contact person who they may contact on my behalf in a
                life-threatening emergency only. This person will only be
                contacted to go to my location or take me to the hospital in the
                event of an emergency. In case of an emergency, I understand
                that I will need to update my home address or, if sessions
                typically take place at another location (such as my office, or
                the home of another person), that I should provide my clinician
                with that address.
              </p>

              <h3
                style={{
                  fontSize: "18px",
                  color: "#000",
                  margin: "20px 0 0",
                  lineHeight: "25px",
                  fontFamily: '"Josefin Sans", sans-serif',
                }}
              >
                My emergency contact person’s name:{" "}
                <u>
                  {type === "Mother"
                    ? upcomingData?.mother_telehealth_consent
                        ?.emergency_contact_name
                    : upcomingData?.daughter_telehealth_consent
                        ?.emergency_contact_name}
                </u>
              </h3>
              <h3
                style={{
                  fontSize: "18px",
                  color: "#000",
                  margin: "20px 0 0",
                  lineHeight: "25px",
                  fontFamily: '"Josefin Sans", sans-serif',
                }}
              >
                Emergency contact person’s phone:{" "}
                <u>
                  {type === "Mother"
                    ? upcomingData?.mother_telehealth_consent
                        ?.emergency_contact_phone_number
                    : upcomingData?.daughter_telehealth_consent
                        ?.emergency_contact_phone_number}
                </u>
              </h3>
              <h3
                style={{
                  fontSize: "18px",
                  color: "#000",
                  margin: "20px 0 0",
                  lineHeight: "25px",
                  fontFamily: '"Josefin Sans", sans-serif',
                }}
              >
                Emergency contact person’s address:{" "}
                <u>
                  {type === "Mother"
                    ? upcomingData?.mother_telehealth_consent
                        ?.emergency_contact_address
                    : upcomingData?.daughter_telehealth_consent
                        ?.emergency_contact_address}
                </u>
              </h3>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                  marginTop: "30px",
                }}
              >
                I have read the information provided above and discussed it with
                my clinician. I understand the information contained in this
                form and all of my questions have been answered to my
                satisfaction. I agree to inform the clinician of any changes to
                my location or environment prior to/or at beginning of session.
              </p>
              <div
                className="Bottom_part"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginTop: "50px",
                  alignItems: "end",
                }}
              >
                <div
                  className="Bottom_part_box"
                  style={{ width: "50%", marginBottom: "50px" }}
                >
                  <span
                    style={{
                      fontWeight: 400,
                      display: "block",
                      width: "50%",
                      borderBottom: "1px solid #3d3d3d",
                    }}
                  >
                    <img
                      src={
                        type === "Mother"
                          ? upcomingData?.mother_telehealth_consent?.signature
                          : upcomingData?.daughter_telehealth_consent?.signature
                      }
                      style={{ width: "50%" }}
                      alt=""
                    />
                  </span>
                  <h3
                    style={{
                      fontSize: "17px",
                      color: "#000",
                      margin: "15px 0 0",
                      fontFamily: '"Josefin Sans", sans-serif',
                    }}
                  >
                    Signature of Client
                  </h3>
                </div>
                <div
                  className="Bottom_part_box"
                  style={{ width: "50%", marginBottom: "50px" }}
                >
                  <span
                    style={{
                      fontWeight: 400,
                      display: "block",
                      width: "50%",
                      borderBottom: "1px solid #3d3d3d",
                    }}
                  >
                    {type === "Mother"
                      ? moment(
                          upcomingData?.mother_telehealth_consent?.date
                        ).format("LL")
                      : moment(
                          upcomingData?.daughter_telehealth_consent?.date
                        ).format("LL")}
                  </span>
                  <h3
                    style={{
                      fontSize: "17px",
                      color: "#000",
                      margin: "15px 0 0",
                      fontFamily: '"Josefin Sans", sans-serif',
                    }}
                  >
                    Date
                  </h3>
                </div>
                <div
                  className="Bottom_part_box"
                  style={{ width: "100%", marginBottom: "50px" }}
                >
                  <span
                    style={{
                      fontWeight: 400,
                      display: "block",
                      width: "100%",
                      borderBottom: "1px solid #3d3d3d",
                    }}
                  >
                    {type === "Mother"
                      ? upcomingData?.mother_telehealth_consent?.address_line1
                      : upcomingData?.daughter_telehealth_consent
                          ?.address_line1}
                  </span>
                  <h3
                    style={{
                      fontSize: "17px",
                      color: "#000",
                      margin: "15px 0 0",
                      fontFamily: '"Josefin Sans", sans-serif',
                    }}
                  >
                    Address line 1
                  </h3>
                </div>
                <div
                  className="Bottom_part_box"
                  style={{ width: "100%", marginBottom: "50px" }}
                >
                  <span
                    style={{
                      fontWeight: 400,
                      display: "block",
                      width: "100%",
                      borderBottom: "1px solid #3d3d3d",
                    }}
                  >
                    {type === "Mother"
                      ? upcomingData?.mother_telehealth_consent?.address_line2
                      : upcomingData?.daughter_telehealth_consent
                          ?.address_line2}
                  </span>

                  <h3
                    style={{
                      fontSize: "17px",
                      color: "#000",
                      margin: "15px 0 0",
                      fontFamily: '"Josefin Sans", sans-serif',
                    }}
                  >
                    Address line 2
                  </h3>
                </div>
                <div
                  className="Bottom_part_box"
                  style={{ width: "33.33%", marginBottom: "50px" }}
                >
                  <span
                    style={{
                      fontWeight: 400,
                      display: "block",
                      width: "70%",
                      borderBottom: "1px solid #3d3d3d",
                    }}
                  >
                    {type === "Mother"
                      ? upcomingData?.mother_telehealth_consent?.city
                      : upcomingData?.daughter_telehealth_consent?.city}
                  </span>

                  <h3
                    style={{
                      fontSize: "17px",
                      color: "#000",
                      margin: "15px 0 0",
                      fontFamily: '"Josefin Sans", sans-serif',
                    }}
                  >
                    City
                  </h3>
                </div>
                <div
                  className="Bottom_part_box"
                  style={{ width: "33.33%", marginBottom: "50px" }}
                >
                  <span
                    style={{
                      fontWeight: 400,
                      display: "block",
                      width: "70%",
                      borderBottom: "1px solid #3d3d3d",
                    }}
                  >
                    {type === "Mother"
                      ? upcomingData?.mother_telehealth_consent?.state
                      : upcomingData?.daughter_telehealth_consent?.state}
                  </span>
                  <h3
                    style={{
                      fontSize: "17px",
                      color: "#000",
                      margin: "15px 0 0",
                      fontFamily: '"Josefin Sans", sans-serif',
                    }}
                  >
                    State{" "}
                  </h3>
                </div>
                <div
                  className="Bottom_part_box"
                  style={{ width: "33.33%", marginBottom: "50px" }}
                >
                  <span
                    style={{
                      fontWeight: 400,
                      display: "block",
                      width: "70%",
                      borderBottom: "1px solid #3d3d3d",
                    }}
                  >
                    {type === "Mother"
                      ? upcomingData?.mother_telehealth_consent?.zip_code
                      : upcomingData?.daughter_telehealth_consent?.zip_code}
                  </span>
                  <h3
                    style={{
                      fontSize: "17px",
                      color: "#000",
                      margin: "15px 0 0",
                      fontFamily: '"Josefin Sans", sans-serif',
                    }}
                  >
                    Zip Code{" "}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TelehealthForm;
