import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { MDBDataTable } from "mdbreact";
import { getSessionByUser, getSessionHistory, getUserData, saveSessionDocumentation, uploadSessionPDF } from "../../apiServices/clinicianPanelHttpServices/loginHttpService/clinicianLoginHttpService";
import ClinicianSidebar from "../commonComponent/clinicianSidebar";
import ClinicianHeader from "../commonComponent/clinicianHeader";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import OwlCarousel from "react-owl-carousel";
import { Tooltip } from "react-tooltip";
import ClinicianDocDummy from "./clinicianDocDummy";

function ClinicianUserDetails() {
  const [user, setUser] = useState("");
  const [indexS1, setIndexS1] = useState(0);
  const [indexS2, setIndexS2] = useState(0);
  const [indexS3, setIndexS3] = useState(0);
  const [indexS4, setIndexS4] = useState(0);
  const [index2S1, setIndex2S1] = useState(0);
  const [index2S2, setIndex2S2] = useState(0);
  const [index2S3, setIndex2S3] = useState(0);
  const [index2S4, setIndex2S4] = useState(0);
  const [index3, setIndex3] = useState(0);
  const [index4, setIndex4] = useState(0);
  const [email, setEmail] = useState("");
  const [from, setFrom] = useState("");
  const [editWorksheet, setEditWorksheet] = useState(false);
  const [till, setTill] = useState("");const [editActivity, setEditActivity] = useState(false);
  const [editJournal, setEditJournal] = useState(false);
  const [getSession, setGetSession] = useState([]);
  const [monthIndex, setMonthIndex] = useState(0);
  const [sessionIndex, setSessionIndex] = useState(0);
  const [type, setType] = useState(0);
  const [userData, setUserData] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [compReason, setCompReason] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [reason, setReason] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm();

  const handleData = (e, key) => {
    const value = e.target.value;
    let data = { ...userData };
    data[key] = value;
    setUserData(data);
  };

  const getActivities = (activities, t) => {
    const activity = activities?.filter(({ type }) => type === t);
    if (activity?.length) {
      return activity[0];
    } else {
      return {};
    }
  };

  const [plan, setPlan] = useState({


    columns: [
      {
        label: "S.No.",
        field: "sn",
        sort: "asc",
        width: 150,
      },
      {
        label: "Plan Name",
        field: "name",
        sort: "asc",
        width: 150,
      },

      {
        label: "Date Of Purchase",
        field: "date",
        sort: "asc",
        width: 100,
      },
      {
        label: "Valid Till",
        field: "till",
        sort: "asc",
        width: 100,
      },
      {
        label: "Plan Price",
        field: "price",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });

  const getSessionDetail = async () => {
    const { data } = await getSessionByUser(id);
    if (!data.error) {
      console.log(data,"callled");
      setGetSession(data.results.session);
      document.getElementById("close-activity").click();
      document.getElementById("close-journal").click();

      let defaultValues = {};

      defaultValues.focus_of_session = userData?.focus_of_session || "";
      defaultValues.goals = userData?.goals || "";
      defaultValues.start_reason = userData?.start_reason || "";
      defaultValues.therapy_history = userData?.therapy_history || "";
      defaultValues.attendees = userData?.attendees || "";
      defaultValues.observations_and_assessment =
        userData?.observations_and_assessment || "";
      defaultValues.plan = userData?.plan || "";
      defaultValues.feedback =
        data.results.session[monthIndex]?.sessions[sessionIndex].feedback ?? "";
      defaultValues.focus_of_session =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .focus_of_session ?? "";

      defaultValues.progress_toward_goals =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .progress_toward_goals ?? "";
      defaultValues.safety_concerns =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .safety_concerns ?? "";
      defaultValues.plan =
        data.results.session[monthIndex]?.sessions[sessionIndex].plan ?? "";

      defaultValues.start_reason =
        data.results.session[monthIndex]?.sessions[sessionIndex].start_reason ??
        "";

      defaultValues.goals =
        data.results.session[monthIndex]?.sessions[sessionIndex].goals ?? "";
      defaultValues.therapy_history =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .therapy_history ?? "";
      defaultValues.observations_and_assessment =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .observations_and_assessment ?? "";
      defaultValues.attendees =
        data.results.session[monthIndex]?.sessions[sessionIndex].attendees ??
        "";

      defaultValues.mother_daughter_relationship =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .mother_daughter_relationship ?? "";
      defaultValues.well_within_relationship =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .well_within_relationship ?? "";
      defaultValues.improve =
        data.results.session[monthIndex]?.sessions[sessionIndex].improve ?? "";
      defaultValues.hope =
        data.results.session[monthIndex]?.sessions[sessionIndex].hope ?? "";
      defaultValues.difficulty_in_relationship =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .difficulty_in_relationship ?? "";
      defaultValues.change =
        data.results.session[monthIndex]?.sessions[sessionIndex].change ?? "";
      defaultValues.previous_experience =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .previous_experience ?? "";
      defaultValues.other_info =
        data.results.session[monthIndex]?.sessions[sessionIndex].other_info ??
        "";
      defaultValues.red_flags_review =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .red_flags_review ?? "";
      defaultValues.mental_health_screen =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .mental_health_screen ?? "";
      defaultValues.social_history =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .social_history ?? "";
      defaultValues.other_pertinent_information =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .other_pertinent_information ?? "";
      defaultValues.observations =
        data.results.session[monthIndex]?.sessions[sessionIndex].observations ??
        "";
      defaultValues.assessment =
        data.results.session[monthIndex]?.sessions[sessionIndex].assessment ??
        "";
      defaultValues.feedback_from_pair =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .feedback_from_pair ?? "";

      console.log(defaultValues);
      reset({ ...defaultValues });
    }
  };

  let { id } = useParams();

  useEffect(() => {
    getDetail();
    getSessionDetail()
  }, []);


  const onSubmit = async (dataa) => {

    if (type === 3) {
      if (!compReason) {
        toast.error("Please enter Comment");
        return;
      }
    }

    dataa.sessionId = getSession[monthIndex].sessions[sessionIndex]._id;
    dataa.sign_date = new Date();
    dataa.type = type;
    if (type === 3) {
      dataa.change_reason = compReason;
    }

    const formData = new FormData();
    for (const item in dataa) {
      formData.append(item, dataa[item]);
      console.log(dataa[item]);
    }

    if (selectedFile) {
      formData.append("signature", selectedFile, selectedFile.name);
    }

    const { data } = await saveSessionDocumentation(formData);
    if (!data.error) {
      console.log(data);

      if (type === 3) {
        document.getElementById("close_reason").click();
      }
      setType(0);
    }
    await getSessionDetail();
    setSelectedFile(null);
  };

  const getDetail = async () => {
    const { data } = await getUserData(id);
    if (!data.error) {
      setUser(data.results.user);
    }
  };

  const onFileSelection = (event) => {
    let file = event[0];

    setSelectedFile(event[0]);
  };
  const onFileSelection2 = (event) => {
    let file = event[0];

    setSelectedFile2(event[0]);
  };

  const uploadPdf = async () => {
    if (!selectedFile2) {
      toast.error("Please add pdf");
      return;
    }
    const formData = new FormData();

    formData.append(
      "sessionId",
      getSession[monthIndex].sessions[sessionIndex]._id
    );

    if (selectedFile2) {
      formData.append("pdf", selectedFile2, selectedFile2.name);
    }

    const { data } = await uploadSessionPDF(formData);
    if (!data.error) {
      await getSessionDetail();
      document.getElementById("upload_pdf1").value = null;
      setSelectedFile2(null);
    }
  };


  return (
    <>
      {" "}
      <div className="admin_main">
        <ClinicianSidebar />
        <div className="admin_main_inner">
          <ClinicianHeader />
          <div className="admin_panel_data height_adjust">
            <div className="row user-details-part justify-content-center">
              <div className="col-12">
                <div className="row mx-0">
                  <div className="col-12 design_outter_comman shadow mb-4">
                    <div className="row comman_header justify-content-between">
                      <div className="col-auto">
                        <h2>User's Information</h2>
                      </div>
                    </div>
                    <form className="row mx-0 align-items-start justify-content-center form-design position-relative p-4 py-5">
                      <div className="col-3">
                        <div className="row">
                          <div className="form-group col-12 mb-2">
                            <div className="userinfor_box text-center">
                              <span className="user_imgg">
                                <img src={user.profile_image} alt="" />
                              </span>
                              <strong>
                                {user.full_name}
                                <div className="sub_name">({user.type})</div>
                              </strong>
                            </div>
                          </div>
                          <div className="form-group col-12 text-center mb-2">
                            <label className="mb-0 text-center" for="">
                              Registration Date:{" "}
                              {moment(user.createdAt).format("L")}
                            </label>
                            <label className="mb-0 text-center" for="">
                              Plan Start Date:{" "}
                              {moment(user.plan_valid_from).format("L")}
                            </label>
                            <label className="mb-0 text-center" for="">
                              Plan End Date:{" "}
                              {moment(user.plan_valid_till).format("L")}
                            </label>
                            <label className="mb-0 text-center" for="">
                              Plan Type: {user.planId?.plan_name}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col px-4">
                        <div className="row">
                          <div className="form-group col-12">
                            <label for="">
                              Pairing Member ({user?.partner?.type}):
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={
                                user?.partner?.full_name ?? user.partner_name
                              }
                              name="name"
                              id="name"
                            />
                          </div>
                          <div className="form-group col-12">
                            <label
                              for=""
                              className="d-flex justify-content-between"
                            >
                              Pairing Member Email:{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={
                                user?.partner?.email ?? user.partner_email
                              }
                              key={user?.partner?.email ?? user.partner_email}
                              name="name"
                              id="name"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="form-group col-12 mb-0">
                            <label for="">Relationship: </label>
                            <input
                              type="text"
                              className="form-control"
                              value={user.relationship}
                              name="name"
                              id="name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col pe-5">
                        <div className="row">
                          <div className="form-group col-12">
                            <label for="">Mobile Number:</label>
                            <input
                              type="text"
                              className="form-control"
                              value={user.phone_number}
                              name="name"
                              id="name"
                            />
                          </div>
                          <div className="form-group col-12">
                            <label for="">Email Id: </label>
                            <input
                              type="text"
                              className="form-control"
                              value={user.email}
                              name="name"
                              id="name"
                            />
                          </div>
                          {user.appleId ? (
                            <div className="form-group col-12 mb-0">
                              <label for="">Apple Id: </label>
                              <input
                                type="text"
                                className="form-control"
                                value={user.appleId}
                                name="name"
                                id="name"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                  {user?.mother_telehealth_consent?.name ||
                  user?.daughter_telehealth_consent?.name ||
                  user?.mother_npp?.name1 ||
                  user?.daughter_npp?.name1 ? (
                    <div class="col-12 design_outter_comman shadow mb-4">
                      <div class="row comman_header justify-content-between">
                        <div class="col-auto">
                          <h2>Forms Signed by Mother & Daughter</h2>
                        </div>
                      </div>
                      <form class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between">
                        <div className="row">
                          {user?.mother_telehealth_consent?.name ? (
                            <div className="col-6">
                              <a
                                href={`/clinician/telehealth-consent-form/${id}/Mother`}
                                class=""
                                target="_blank"
                              >
                                <div class="comman_btn2 me-3">
                                  Telehealth Consent By Mother
                                </div>
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                          {user?.mother_npp?.name1 ? (
                            <div className="col-6 ">
                              <a
                                href={`/admin/notice-of-privacy-policies-form/${id}/Mother`}
                                class=""
                                target="_blank"
                              >
                                <div class="comman_btn2 me-3">
                                  NPP & Practice Policies By Mother
                                </div>
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                          {user?.daughter_telehealth_consent?.name ? (
                            <div className="col-6 mt-4">
                              <a
                                href={`/admin/telehealth-consent-form/${id}/Daughter`}
                                class=""
                                target="_blank"
                              >
                                <div class="comman_btn2 me-3">
                                  Telehealth Consent By Daughter
                                </div>
                              </a>
                            </div>
                          ) : (
                            ""
                          )}

                          {user?.daughter_npp?.name1 ? (
                            <div className="col-6 mt-4">
                              <a
                                href={`/admin/notice-of-privacy-policies-form/${id}/Daughter`}
                                class=""
                                target="_blank"
                              >
                                <div class="comman_btn2 me-3">
                                  NPP & Practice Policies By Daughter
                                </div>
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </form>
                    </div>
                  ) : (
                    ""
                  )}
                  {user?.answers?.length ? (
                    <div class="col-12 design_outter_comman recent_orders shadow mb-4">
                      <div class="row comman_header justify-content-between">
                        <div class="col">
                          <h2>
                            Monthly Plan's Question & Answer by {user.type}
                          </h2>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 user_tabbing py-4 px-4">
                          <nav>
                            <div
                              class="nav nav-tabs d-flex justify-content-start border-0"
                              id="nav-tab"
                              role="tablist"
                            >
                              {user?.answers?.map((item, index) => {
                                return (
                                  <button
                                    class={
                                      index === indexS1
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    id={`nav-${index}-tab`}
                                    data-bs-toggle="tab"
                                    data-bs-target={`#nav-${index}`}
                                    type="button"
                                    role="tab"
                                    aria-controls={`nav-${index}`}
                                    aria-selected="true"
                                    onClick={() => setIndexS1(index)}
                                  >
                                    {index + 1}
                                  </button>
                                );
                              })}
                            </div>
                          </nav>
                          {user.answers ? (
                            <div class="tab-content mt-4" id="nav-tabContent">
                              <div
                                class="tab-pane fade show active"
                                id={`nav-${indexS1}`}
                                role="tabpanel"
                                aria-labelledby={`nav-${indexS1}-tab`}
                              >
                                <form class="row question_ansbox justify-content-center">
                                  <h3 class="mb-4">
                                    {
                                      user?.answers[indexS1]?.questionId
                                        ?.question
                                    }
                                  </h3>

                                  {user?.answers[
                                    indexS1
                                  ]?.questionId?.options?.map((item, index) => {
                                    console.log(
                                      user.answers[indexS1].answer,
                                      item
                                    );
                                    return (
                                      <div class="form-group col-auto custom_radio">
                                        <input
                                          type="radio"
                                          id={`radio1${user?.answers[indexS1]?.questionId}${index}`}
                                          name="radio1"
                                          class="d-none"
                                          checked={
                                            item ===
                                            user.answers[indexS1].answer
                                          }
                                        />
                                        <label
                                          for={`radio1${user?.answers[indexS1]?.questionId}${index}`}
                                        >
                                          {item}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </form>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {user?.answers_3month?.length ? (
                        <div class="row">
                          <label>Question & Answer 3rd Month</label>
                          <div class="col-12 user_tabbing py-4 px-4">
                            <nav>
                              <div
                                class="nav nav-tabs d-flex justify-content-start border-0"
                                id="nav-tab"
                                role="tablist"
                              >
                                {user?.answers_3month?.map((item, index) => {
                                  return (
                                    <button
                                      class={
                                        index === indexS2
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      id={`nav-${index}-tab`}
                                      data-bs-toggle="tab"
                                      data-bs-target={`#nav-${index}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`nav-${index}`}
                                      aria-selected="true"
                                      onClick={() => setIndexS2(index)}
                                    >
                                      {index + 1}
                                    </button>
                                  );
                                })}
                              </div>
                            </nav>
                            {user.answers_3month ? (
                              <div class="tab-content mt-4" id="nav-tabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id={`nav-${indexS2}`}
                                  role="tabpanel"
                                  aria-labelledby={`nav-${indexS2}-tab`}
                                >
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        user?.answers_3month[indexS2]
                                          ?.questionId?.question
                                      }
                                    </h3>

                                    {user?.answers_3month[
                                      indexS2
                                    ]?.questionId?.options?.map(
                                      (item, index) => {
                                        console.log(
                                          user?.answers_3month[indexS2].answer,
                                          item
                                        );
                                        return (
                                          <div class="form-group col-auto custom_radio">
                                            <input
                                              type="radio"
                                              id={`radio1${user?.answers_3month[indexS2]?.questionId}${index}`}
                                              name="radio1"
                                              class="d-none"
                                              checked={
                                                item ===
                                                user?.answers_3month[indexS2]
                                                  .answer
                                              }
                                            />
                                            <label
                                              for={`radio1${user?.answers_3month[indexS2]?.questionId}${index}`}
                                            >
                                              {item}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </form>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {user?.answers_6month?.length ? (
                        <div class="row">
                          <label>Question & Answer 6th Month</label>
                          <div class="col-12 user_tabbing py-4 px-4">
                            <nav>
                              <div
                                class="nav nav-tabs d-flex justify-content-start border-0"
                                id="nav-tab"
                                role="tablist"
                              >
                                {user?.answers_9month?.map((item, index) => {
                                  return (
                                    <button
                                      class={
                                        index === indexS3
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      id={`nav-${index}-tab`}
                                      data-bs-toggle="tab"
                                      data-bs-target={`#nav-${index}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`nav-${index}`}
                                      aria-selected="true"
                                      onClick={() => setIndexS3(index)}
                                    >
                                      {index + 1}
                                    </button>
                                  );
                                })}
                              </div>
                            </nav>
                            {user?.answers_6month ? (
                              <div class="tab-content mt-4" id="nav-tabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id={`nav-${indexS3}`}
                                  role="tabpanel"
                                  aria-labelledby={`nav-${indexS3}-tab`}
                                >
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        user?.answers[indexS3]?.questionId
                                          ?.question
                                      }
                                    </h3>

                                    {user?.answers_6month[
                                      indexS3
                                    ]?.questionId?.options?.map(
                                      (item, index) => {
                                        console.log(
                                          user.answers_6month[indexS3].answer,
                                          item
                                        );
                                        return (
                                          <div class="form-group col-auto custom_radio">
                                            <input
                                              type="radio"
                                              id={`radio1${user?.answers_6month[indexS3]?.questionId}${index}`}
                                              name="radio1"
                                              class="d-none"
                                              checked={
                                                item ===
                                                user?.answers_6month[indexS3]
                                                  .answer
                                              }
                                            />
                                            <label
                                              for={`radio1${user?.answers_6month[indexS3]?.questionId}${index}`}
                                            >
                                              {item}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </form>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {user?.answers_9month?.length ? (
                        <div class="row">
                          <label>Question & Answer 9th Month</label>
                          <div class="col-12 user_tabbing py-4 px-4">
                            <nav>
                              <div
                                class="nav nav-tabs d-flex justify-content-start border-0"
                                id="nav-tab"
                                role="tablist"
                              >
                                {user?.answers_9month?.map((item, index) => {
                                  return (
                                    <button
                                      class={
                                        index === indexS4
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      id={`nav-${index}-tab`}
                                      data-bs-toggle="tab"
                                      data-bs-target={`#nav-${index}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`nav-${index}`}
                                      aria-selected="true"
                                      onClick={() => setIndexS4(index)}
                                    >
                                      {index + 1}
                                    </button>
                                  );
                                })}
                              </div>
                            </nav>
                            {user?.answers_9month ? (
                              <div class="tab-content mt-4" id="nav-tabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id={`nav-${indexS4}`}
                                  role="tabpanel"
                                  aria-labelledby={`nav-${indexS4}-tab`}
                                >
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        user?.answers[indexS4]?.questionId
                                          ?.question
                                      }
                                    </h3>

                                    {user?.answers_9month[
                                      indexS4
                                    ]?.questionId?.options?.map(
                                      (item, index) => {
                                        console.log(
                                          user?.answers_9month[indexS4].answer,
                                          item
                                        );
                                        return (
                                          <div class="form-group col-auto custom_radio">
                                            <input
                                              type="radio"
                                              id={`radio1${user?.answers_9month[indexS4]?.questionId}${index}`}
                                              name="radio1"
                                              class="d-none"
                                              checked={
                                                item ===
                                                user?.answers_9month[indexS4]
                                                  .answer
                                              }
                                            />
                                            <label
                                              for={`radio1${user?.answers_9month[indexS4]?.questionId}${index}`}
                                            >
                                              {item}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </form>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {user?.partner?.answers?.length ? (
                    <div class="col-12 design_outter_comman recent_orders shadow mb-4">
                      <div class="row comman_header justify-content-between">
                        <div class="col">
                          <h2>
                            Monthly Plan's Question & Answer by{" "}
                            {user?.partner?.type}
                          </h2>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 user_tabbing py-4 px-4">
                          <nav>
                            <div
                              class="nav nav-tabs d-flex justify-content-start border-0"
                              id="nav-tab"
                              role="tablist"
                            >
                              {user?.partner.answers?.map((item, index) => {
                                return (
                                  <button
                                    class={
                                      index === index2S1
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    id={`nav-${index}-tab`}
                                    data-bs-toggle="tab"
                                    data-bs-target={`#nav-${index}`}
                                    type="button"
                                    role="tab"
                                    aria-controls={`nav-${index}`}
                                    aria-selected="true"
                                    onClick={() => setIndex2S1(index)}
                                  >
                                    {index + 1}
                                  </button>
                                );
                              })}
                            </div>
                          </nav>
                          {user?.partner?.answers ? (
                            <div class="tab-content mt-4" id="nav-tabContent">
                              <div
                                class="tab-pane fade show active"
                                id={`nav-${index2S1}`}
                                role="tabpanel"
                                aria-labelledby={`nav-${index2S1}-tab`}
                              >
                                <form class="row question_ansbox justify-content-center">
                                  <h3 class="mb-4">
                                    {
                                      user?.partner.answers[index2S1]
                                        ?.questionId?.question
                                    }
                                  </h3>

                                  {user?.partner.answers[
                                    index2S1
                                  ]?.questionId?.options?.map((item, index) => {
                                    console.log(
                                      user.partner.answers[index2S1].answer,
                                      item
                                    );
                                    return (
                                      <div class="form-group col-auto custom_radio">
                                        <input
                                          type="radio"
                                          id={`radio1${user?.partner.answers[index2S1]?.questionId}${index}`}
                                          name="radio1"
                                          class="d-none"
                                          checked={
                                            item ===
                                            user.partner.answers[index2S1]
                                              .answer
                                          }
                                        />
                                        <label
                                          for={`radio1${user?.partner.answers[index2S1]?.questionId}${index}`}
                                        >
                                          {item}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </form>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      {user?.partner?.answers_3month?.length ? (
                        <div class="row">
                          <label>Question & Answer 3rd Month</label>
                          <div class="col-12 user_tabbing py-4 px-4">
                            <nav>
                              <div
                                class="nav nav-tabs d-flex justify-content-start border-0"
                                id="nav-tab"
                                role="tablist"
                              >
                                {user?.partner?.answers_3month?.map(
                                  (item, index) => {
                                    return (
                                      <button
                                        class={
                                          index === index2S2
                                            ? "nav-link active"
                                            : "nav-link"
                                        }
                                        id={`nav-${index}-tab`}
                                        data-bs-toggle="tab"
                                        data-bs-target={`#nav-${index}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`nav-${index}`}
                                        aria-selected="true"
                                        onClick={() => setIndex2S2(index)}
                                      >
                                        {index + 1}
                                      </button>
                                    );
                                  }
                                )}
                              </div>
                            </nav>
                            {user?.partner?.answers_3month ? (
                              <div class="tab-content mt-4" id="nav-tabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id={`nav-${index2S2}`}
                                  role="tabpanel"
                                  aria-labelledby={`nav-${index2S2}-tab`}
                                >
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        user?.partner?.answers_3month[index2S2]
                                          ?.questionId?.question
                                      }
                                    </h3>

                                    {user?.partner?.answers_3month[
                                      index2S2
                                    ]?.questionId?.options?.map(
                                      (item, index) => {
                                        console.log(
                                          user?.partner?.answers_3month[
                                            index2S2
                                          ].answer,
                                          item
                                        );
                                        return (
                                          <div class="form-group col-auto custom_radio">
                                            <input
                                              type="radio"
                                              id={`radio1${user?.partner?.answers_3month[index2S2]?.questionId}${index}`}
                                              name="radio1"
                                              class="d-none"
                                              checked={
                                                item ===
                                                user?.partner?.answers_3month[
                                                  index2S2
                                                ].answer
                                              }
                                            />
                                            <label
                                              for={`radio1${user?.partner?.answers_3month[index2S2]?.questionId}${index}`}
                                            >
                                              {item}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </form>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {user?.partner?.answers_6month?.length ? (
                        <div class="row">
                          <label>Question & Answer 6th Month</label>
                          <div class="col-12 user_tabbing py-4 px-4">
                            <nav>
                              <div
                                class="nav nav-tabs d-flex justify-content-start border-0"
                                id="nav-tab"
                                role="tablist"
                              >
                                {user?.partner?.answers_6month?.map(
                                  (item, index) => {
                                    return (
                                      <button
                                        class={
                                          index === index2S3
                                            ? "nav-link active"
                                            : "nav-link"
                                        }
                                        id={`nav-${index}-tab`}
                                        data-bs-toggle="tab"
                                        data-bs-target={`#nav-${index}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`nav-${index}`}
                                        aria-selected="true"
                                        onClick={() => setIndex2S3(index)}
                                      >
                                        {index + 1}
                                      </button>
                                    );
                                  }
                                )}
                              </div>
                            </nav>
                            {user?.partner?.answers_6month ? (
                              <div class="tab-content mt-4" id="nav-tabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id={`nav-${index2S3}`}
                                  role="tabpanel"
                                  aria-labelledby={`nav-${index2S3}-tab`}
                                >
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        user?.partner?.answers_6month[index2S3]
                                          ?.questionId?.question
                                      }
                                    </h3>

                                    {user?.partner?.answers_6month[
                                      index2S3
                                    ]?.questionId?.options?.map(
                                      (item, index) => {
                                        console.log(
                                          user?.answers_6month[index2S3]
                                            ?.answer,
                                          item
                                        );
                                        return (
                                          <div class="form-group col-auto custom_radio">
                                            <input
                                              type="radio"
                                              id={`radio1${user?.partner?.answers_6month[index2S3]?.questionId}${index}`}
                                              name="radio1"
                                              class="d-none"
                                              checked={
                                                item ===
                                                user?.partner?.answers_6month[
                                                  index2S3
                                                ].answer
                                              }
                                            />
                                            <label
                                              for={`radio1${user?.partner?.answers_6month[index2S3]?.questionId}${index}`}
                                            >
                                              {item}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </form>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {user?.partner?.answers_9month?.length ? (
                        <div class="row">
                          <label>Question & Answer 9th Month</label>
                          <div class="col-12 user_tabbing py-4 px-4">
                            <nav>
                              <div
                                class="nav nav-tabs d-flex justify-content-start border-0"
                                id="nav-tab"
                                role="tablist"
                              >
                                {user?.partner?.answers_9month?.map(
                                  (item, index) => {
                                    return (
                                      <button
                                        class={
                                          index === index2S4
                                            ? "nav-link active"
                                            : "nav-link"
                                        }
                                        id={`nav-${index}-tab`}
                                        data-bs-toggle="tab"
                                        data-bs-target={`#nav-${index}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`nav-${index}`}
                                        aria-selected="true"
                                        onClick={() => setIndex2S4(index)}
                                      >
                                        {index + 1}
                                      </button>
                                    );
                                  }
                                )}
                              </div>
                            </nav>
                            {user?.partner?.answers_9month ? (
                              <div class="tab-content mt-4" id="nav-tabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id={`nav-${index2S4}`}
                                  role="tabpanel"
                                  aria-labelledby={`nav-${index2S4}-tab`}
                                >
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        user?.answers[index2S4]?.questionId
                                          ?.question
                                      }
                                    </h3>

                                    {user?.partner?.answers_9month[
                                      index2S4
                                    ]?.questionId?.options?.map(
                                      (item, index) => {
                                        console.log(
                                          user?.answers_9month[index2S4]
                                            ?.answer,
                                          item
                                        );
                                        return (
                                          <div class="form-group col-auto custom_radio">
                                            <input
                                              type="radio"
                                              id={`radio1${user?.partner?.answers_9month[index2S4]?.questionId}${index}`}
                                              name="radio1"
                                              class="d-none"
                                              checked={
                                                item ===
                                                user?.answers_9month[index2S4]
                                                  ?.answer
                                              }
                                            />
                                            <label
                                              for={`radio1${user?.partner?.answers_9month[index2S4]?.questionId}${index}`}
                                            >
                                              {item}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </form>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {user?.gold_answers?.length ? (
                    <div class="col-12 design_outter_comman recent_orders shadow mb-4">
                      <div class="row comman_header justify-content-between">
                        <div class="col">
                          <h2>
                            Intake Plan's Question & Answer by {user.type}
                          </h2>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 user_tabbing py-4 px-4">
                          <nav>
                            <div
                              class="nav nav-tabs d-flex justify-content-start border-0"
                              id="nav-tab"
                              role="tablist"
                            >
                              {user?.gold_answers?.map((item, index) => {
                                return (
                                  <button
                                    class={
                                      index === index3
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    id={`nav-${index}-tab`}
                                    data-bs-toggle="tab"
                                    data-bs-target={`#nav-${index}`}
                                    type="button"
                                    role="tab"
                                    aria-controls={`nav-${index}`}
                                    aria-selected="true"
                                    onClick={() => setIndex3(index)}
                                  >
                                    {index + 1}
                                  </button>
                                );
                              })}
                            </div>
                          </nav>
                          {user.gold_answers ? (
                            <div class="tab-content mt-4" id="nav-tabContent">
                              <div
                                class="tab-pane fade show active"
                                id={`nav-${index3}`}
                                role="tabpanel"
                                aria-labelledby={`nav-${index3}-tab`}
                              >
                                <form class="row question_ansbox justify-content-center">
                                  <h3 class="mb-4">
                                    {
                                      user?.gold_answers[index3]?.questionId
                                        ?.question
                                    }
                                  </h3>
                                  <label>
                                    {user?.gold_answers[index3]?.answer.length >
                                    1
                                      ? user?.gold_answers[index3]?.answer.map(
                                          (item, index) => {
                                            return (
                                              <>
                                                <label>{item}</label> <br />
                                              </>
                                            );
                                          }
                                        )
                                      : user?.gold_answers[index3]?.answer}
                                  </label>
                                </form>
                                {user?.gold_answers[index3]?.answer2 ? (
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        user?.gold_answers[index3]?.questionId
                                          ?.question2
                                      }
                                    </h3>
                                    <label>
                                      {user?.gold_answers[index3]?.answer2}
                                    </label>
                                  </form>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {user?.partner?.gold_answers?.length ? (
                    <div class="col-12 design_outter_comman recent_orders shadow mb-4">
                      <div class="row comman_header justify-content-between">
                        <div class="col">
                          <h2>
                            {" "}
                            Intake Plan's Question & Answer by{" "}
                            {user?.partner?.type}
                          </h2>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 user_tabbing py-4 px-4">
                          <nav>
                            <div
                              class="nav nav-tabs d-flex justify-content-start border-0"
                              id="nav-tab"
                              role="tablist"
                            >
                              {user?.partner.gold_answers?.map(
                                (item, index) => {
                                  return (
                                    <button
                                      class={
                                        index === index4
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      id={`nav-${index}-tab`}
                                      data-bs-toggle="tab"
                                      data-bs-target={`#nav-${index}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`nav-${index}`}
                                      aria-selected="true"
                                      onClick={() => setIndex4(index)}
                                    >
                                      {index + 1}
                                    </button>
                                  );
                                }
                              )}
                            </div>
                          </nav>
                          {user.partner.gold_answers ? (
                            <div class="tab-content mt-4" id="nav-tabContent">
                              <div
                                class="tab-pane fade show active"
                                id={`nav-${index4}`}
                                role="tabpanel"
                                aria-labelledby={`nav-${index4}-tab`}
                              >
                                <form class="row question_ansbox justify-content-center">
                                  <h3 class="mb-4">
                                    {
                                      user?.partner.gold_answers[index4]
                                        ?.questionId?.question
                                    }
                                  </h3>

                                  <label>
                                    {user?.partner?.gold_answers[index4]?.answer
                                      .length > 1
                                      ? user?.partner?.gold_answers[
                                          index4
                                        ]?.answer.map((item, index) => {
                                          return (
                                            <>
                                              <label>{item}</label> <br />
                                            </>
                                          );
                                        })
                                      : user?.partner?.gold_answers[index4]
                                          ?.answer}
                                  </label>
                                </form>
                                {user?.partner?.gold_answers[index4]
                                  ?.answer2 ? (
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        user?.partner?.gold_answers[index4]
                                          ?.questionId?.question2
                                      }
                                    </h3>
                                    <label>
                                      {
                                        user?.partner?.gold_answers[index4]
                                          ?.answer2
                                      }
                                    </label>
                                  </form>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div class="col-12 mt-4">
                <div class="row">
                  <div class="col-12 mb-4 main_heading text-center">
                    <h1>Session History</h1>
                  </div>
                  <div class="col-12 design_outter_comman shadow mb-4">
                    <div class="row py-4 px-4">
                      <div class="col-12 mb-4">
                          <OwlCarousel
                            className="slider_session owl-carousel"
                            autoplay={false}
                            autoplayHoverPause={true}
                            autoplayTimeout={5000}
                            dots={false}
                            loop={false}
                            autoWidth={false}
                            nav={true}
                            fade={true}
                            items={getSession.length}
                            stagePadding={20}
                          >
                            {getSession?.map((item, index) => (
                              <Link
                                class="slider_menu item active"
                                onClick={() => {
                                  setMonthIndex(index);
                                  setSessionIndex(0);
                                }}
                                to=""
                              >
                                {item.month.month}
                              </Link>
                            ))}
                          </OwlCarousel>
                     
                      </div>

                      <div
                        id="video_list_1"
                        class="video_list col-12 session_history_tabs px-0 border rounded overflow-hidden"
                      >
                        <nav>
                          <div
                            class="nav two_navtabs nav-tabs justify-content-center border-0"
                            id="nav-tab"
                            role="tablist"
                          >
                            {getSession[monthIndex]?.sessions?.map(
                              (item, index) => (
                                <button
                                  class={
                                    sessionIndex === index
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  id={`nav-home-tab${index}`}
                                  data-bs-toggle="tab"
                                  data-bs-target={`#nav-${index}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={`nav-${index}`}
                                  aria-selected="true"
                                  onClick={() => setSessionIndex(index)}
                                >
                                  {item.name}
                                </button>
                              )
                            )}
                          </div>
                        </nav>
                        <div class="tab-content" id="nav-tabContent">
                          <div
                            class="tab-pane fade show active"
                            id={`nav-${sessionIndex}`}
                            role="tabpanel"
                            aria-labelledby={`nav-home-tab${sessionIndex}`}
                          >
                            <div class="row mx-0 p-4">
                              <div class="col-12 design_outter_comman border rounded mb-4">
                                <div class="row comman_header justify-content-between">
                                  <div class="col-auto">
                                    <h2>Session Schedule</h2>
                                  </div>
                                </div>
                                <form class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between">
                                  <div class="form-group mb-0 col">
                                    <label for="">Date</label>
                                    <input
                                      type="type"
                                      class="form-control"
                                      value={
                                        getSession[monthIndex]?.sessions[
                                          sessionIndex
                                        ]?.date
                                          ? moment(
                                              getSession[monthIndex]?.sessions[
                                                sessionIndex
                                              ]?.date
                                            ).format("L")
                                          : ""
                                      }
                                      name="name"
                                      id="name"
                                    />
                                  </div>
                                  <div class="form-group mb-0 col">
                                    <label for="">Time</label>
                                    <input
                                      type="type"
                                      class="form-control"
                                      value={
                                        getSession[monthIndex]?.sessions[
                                          sessionIndex
                                        ]?.time
                                          ? getSession[monthIndex]?.sessions[
                                              sessionIndex
                                            ]?.time
                                          : ""
                                      }
                                      name="name"
                                      id="name"
                                    />
                                  </div>
                                  <div class="form-group mb-0 col">
                                    <label for="">Duration</label>
                                    <input
                                      type="type"
                                      class="form-control"
                                      value={
                                        getSession[monthIndex]?.sessions[
                                          sessionIndex
                                        ]?.duration
                                          ? getSession[monthIndex]?.sessions[
                                              sessionIndex
                                            ]?.duration
                                          : ""
                                      }
                                      name="name"
                                      id="name"
                                    />
                                  </div>
                                </form>
                              </div>

                              {getSession[monthIndex]?.sessions[sessionIndex]
                                ?.plan_type === 1 ? (
                                <>
                                  <div class="col-12 design_outter_comman border rounded mb-4">
                                    <div class="row comman_header justify-content-between">
                                      <div class="col-auto">
                                        <h2>Assigned Activity</h2>
                                      </div>
                                      {getActivities(
                                        getSession[monthIndex]?.activities,
                                        "Activity"
                                      ).activity ? (
                                        <div className="col-auto">
                                          <a
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop07"
                                            className="edit_form"
                                            onClick={() => {
                                              setEditActivity(true);

                                              setSessionId(
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Activity"
                                                ).session
                                              );
                                            }}
                                          >
                                            <i className="fas fa-edit"></i>
                                          </a>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <form class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between">
                                      <div class="form-group col mb-0 choose_file position-relative">
                                        {getActivities(
                                          getSession[monthIndex]?.activities,
                                          "Activity"
                                        ).activity?.image ? (
                                          <div class="user_response_box me-3">
                                            <img
                                              src={
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Activity"
                                                ).activity?.image
                                              }
                                              alt=""
                                            />
                                          </div>
                                        ) : (
                                          <>
                                            <Link
                                              class="comman_btn"
                                              to=""
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop07"
                                              onClick={() =>
                                                setSessionId(
                                                  getSession[monthIndex]
                                                    .sessions[sessionIndex]._id
                                                )
                                              }
                                            >
                                              Select Activity
                                            </Link>
                                          </>
                                        )}
                                      </div>
                                      {getActivities(
                                        getSession[monthIndex]?.activities,
                                        "Activity"
                                      ).activity?.message ? (
                                        <div class="form-group col ">
                                          <label for="">Message</label>

                                          <input
                                            type="text"
                                            class="form-control"
                                            value={
                                              getActivities(
                                                getSession[monthIndex]
                                                  ?.activities,
                                                "Activity"
                                              ).activity?.message
                                            }
                                            name="name"
                                            id="name"
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </form>
                                    <div class="row mx-0 user_response text-center pb-4 align-items-center">
                                      <div className="col-6">
                                        <div class="col-12 mb-2">
                                          <label for="">
                                            Mother's Response
                                          </label>
                                        </div>
                                        <div class="col-12">
                                          <div class="d-flex align-items-center justify-content-start">
                                            <div class="user_response_box me-3">
                                              <img
                                                src={
                                                  getActivities(
                                                    getSession[monthIndex]
                                                      ?.activities,
                                                    "Activity"
                                                  ).mother_response
                                                    ? getActivities(
                                                        getSession[monthIndex]
                                                          ?.activities,
                                                        "Activity"
                                                      ).mother_response
                                                    : "/assets/img/women.png"
                                                }
                                                alt=""
                                              />
                                            </div>

                                            {getActivities(
                                              getSession[monthIndex]
                                                ?.activities,
                                              "Activity"
                                            ).mother_response ? (
                                              <a
                                                class="comman_btn2"
                                                href={
                                                  getActivities(
                                                    getSession[monthIndex]
                                                      ?.activities,
                                                    "Activity"
                                                  ).mother_response ?? ""
                                                }
                                                target="_blank"
                                              >
                                                <i class="fal fa-download me-2"></i>
                                                Download
                                              </a>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div class="col-12 mb-2">
                                          <label for="">
                                            Daughter's Response
                                          </label>
                                        </div>
                                        <div class="col-12">
                                          <div class="d-flex align-items-center justify-content-start">
                                            <div class="user_response_box me-3">
                                              <img
                                                src={
                                                  getActivities(
                                                    getSession[monthIndex]
                                                      ?.activities,
                                                    "Activity"
                                                  ).daughter_response
                                                    ? getActivities(
                                                        getSession[monthIndex]
                                                          ?.activities,
                                                        "Activity"
                                                      ).daughter_response
                                                    : "/assets/img/women.png"
                                                }
                                                alt=""
                                              />
                                            </div>

                                            {getActivities(
                                              getSession[monthIndex]
                                                ?.activities,
                                              "Activity"
                                            ).daughter_response ? (
                                              <a
                                                class="comman_btn2"
                                                href={
                                                  getActivities(
                                                    getSession[monthIndex]
                                                      ?.activities,
                                                    "Activity"
                                                  ).daughter_response ?? ""
                                                }
                                                target="_blank"
                                              >
                                                <i class="fal fa-download me-2"></i>
                                                Download
                                              </a>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-12 design_outter_comman border rounded mb-4">
                                    <div class="row comman_header justify-content-between">
                                      <div class="col-auto">
                                        <h2>Suggest A Journal</h2>
                                      </div>
                                      {getActivities(
                                        getSession[monthIndex]?.activities,
                                        "Journal"
                                      ).journal ? (
                                        <div className="col-auto">
                                          <a
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop08"
                                            className="edit_form"
                                            onClick={() => {
                                              setEditJournal(true);

                                              setSessionId(
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Journal"
                                                ).session
                                              );
                                            }}
                                          >
                                            <i className="fas fa-edit"></i>
                                          </a>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <form class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between">
                                      <div class="form-group col-10">
                                        {getActivities(
                                          getSession[monthIndex]?.activities,
                                          "Journal"
                                        ).journal?.topic ? (
                                          <>
                                            <label for="">Journal Topic</label>
                                            <input
                                              type="text"
                                              class="form-control"
                                              value={
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Journal"
                                                ).journal?.topic
                                              }
                                              name="name"
                                              id="name"
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <Link
                                              class="comman_btn"
                                              to=""
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop08"
                                              onClick={() =>
                                                setSessionId(
                                                  getSession[monthIndex]
                                                    .sessions[sessionIndex]._id
                                                )
                                              }
                                            >
                                              Select Journal
                                            </Link>
                                          </>
                                        )}
                                      </div>

                                      <div class="form-group col-12 mb-0">
                                        <label for="">Mother's Response</label>
                                      </div>
                                      <div class="form-group col-10">
                                        <input
                                          type="text"
                                          class="form-control"
                                          value={
                                            getActivities(
                                              getSession[monthIndex]
                                                ?.activities,
                                              "Journal"
                                            ).mother_response
                                          }
                                          name="name"
                                          id="name"
                                        />
                                      </div>

                                      <div class="form-group col-4 ">
                                        <div class="row  align-items-center">
                                          {getActivities(
                                            getSession[monthIndex]?.activities,
                                            "Journal"
                                          ).mother_audio ? (
                                            <a
                                              className="comman_btn2 text-center w-50"
                                              target="_blank"
                                              href={
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Journal"
                                                ).mother_audio
                                              }
                                              // src="http://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Sevish_-__nbsp_.mp3"
                                            >
                                              Play
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div class="form-group col-12 mb-0 ">
                                        <label for="">
                                          Daughter's Response
                                        </label>
                                      </div>
                                      <div class="form-group col-10">
                                        <input
                                          type="text"
                                          class="form-control"
                                          value={
                                            getActivities(
                                              getSession[monthIndex]
                                                ?.activities,
                                              "Journal"
                                            ).daughter_response
                                          }
                                          name="name"
                                          id="name"
                                        />
                                      </div>

                                      <div class="form-group col-4 mb-0">
                                        <div class="row  align-items-center">
                                          {getActivities(
                                            getSession[monthIndex]?.activities,
                                            "Journal"
                                          ).daughter_audio ? (
                                            <a
                                              className="comman_btn2 text-center w-50"
                                              href={
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Journal"
                                                ).daughter_audio
                                              }
                                              // src="http://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Sevish_-__nbsp_.mp3"

                                              target="_blank"
                                            >
                                              Play
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </form>
                                  </div>

                                  <div class="col-12 design_outter_comman border rounded mb-4">
                                    <div class="row comman_header justify-content-between">
                                      <div class="col-auto d-flex">
                                        <h2>Assigned Worksheet</h2>
                                        {getActivities(
                                          getSession[monthIndex]?.activities,
                                          "Worksheet"
                                        )?.isCompletedByDaughter ||
                                        getActivities(
                                          getSession[monthIndex]?.activities,
                                          "Worksheet"
                                        )?.isCompletedByMother ? (
                                          <a
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={
                                              getActivities(
                                                getSession[monthIndex]
                                                  ?.activities,
                                                "Worksheet"
                                              )?.isCompletedByDaughter &&
                                              getActivities(
                                                getSession[monthIndex]
                                                  ?.activities,
                                                "Worksheet"
                                              )?.isCompletedByMother
                                                ? "Complete By Mother & Daughter"
                                                : getActivities(
                                                    getSession[monthIndex]
                                                      ?.activities,
                                                    "Worksheet"
                                                  )?.isCompletedByMother
                                                ? "Complete By Mother"
                                                : "Complete By Daughter"
                                            }
                                            data-tooltip-place="top"
                                          >
                                            <i
                                              className="fa fa-check-circle ms-2"
                                              style={{
                                                color: "#fff",
                                                fontSize: "20px",
                                              }}
                                            ></i>
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                        <Tooltip id="my-tooltip" />
                                      </div>

                                      {getActivities(
                                        getSession[monthIndex]?.activities,
                                        "Worksheet"
                                      ).worksheet ? (
                                        <div className="col-auto">
                                          <a
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop09"
                                            className="edit_form"
                                            onClick={() => {
                                              setEditWorksheet(true);
                                              setSessionId(
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Worksheet"
                                                ).session
                                              );
                                            }}
                                          >
                                            <i className="fas fa-edit"></i>
                                          </a>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <form class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between">
                                      <div class="form-group col  choose_file position-relative">
                                        {getActivities(
                                          getSession[monthIndex]?.activities,
                                          "Worksheet"
                                        ).worksheet?.pdf ? (
                                          <>
                                            <a
                                              href={
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Worksheet"
                                                ).worksheet?.pdf
                                              }
                                              target="_blank"
                                            >
                                              <i
                                                className="fa fa-file-pdf-o"
                                                style={{
                                                  fontSize: "65px",
                                                  color: "#000",
                                                  marginBottom: "-10px",
                                                }}
                                              ></i>
                                            </a>
                                          </>
                                        ) : (
                                          <Link
                                            class="comman_btn"
                                            to=""
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop09"
                                            onClick={() =>
                                              setSessionId(
                                                getSession[monthIndex].sessions[
                                                  sessionIndex
                                                ]._id
                                              )
                                            }

                                            // onClick={() => assignedActivity()}
                                          >
                                            Select Worksheet
                                          </Link>
                                        )}
                                      </div>
                                      {getActivities(
                                        getSession[monthIndex]?.activities,
                                        "Worksheet"
                                      ).worksheet?.message ? (
                                        <>
                                          <div class="form-group col ">
                                            <label for="">Message</label>
                                            <input
                                              type="text"
                                              class="form-control"
                                              value={
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Worksheet"
                                                ).worksheet?.message
                                              }
                                              name="name"
                                              id="name"
                                            />
                                          </div>{" "}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </form>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              <>

                              {getSession[monthIndex]?.sessions[sessionIndex]
                                .plan_type == 1 ? (
                                <div class="col-12 design_outter_comman border rounded mb-4">
                                  <div class="row comman_header justify-content-between">
                                    <div class="col">
                                      <h2>Clinical Documentation</h2>
                                    </div>
                                    <div className="col-auto">
                                      <div class="form-group  mb-0 choose_file position-relative">
                                        {getSession[monthIndex]?.sessions[
                                          sessionIndex
                                        ].pdf ? (
                                          <a
                                            href={`${getSession[monthIndex]?.sessions[sessionIndex].pdf}`}
                                            target="blank"
                                          >
                                            <h2>Download Pdf</h2>
                                          </a>
                                        ) : (
                                          <Link to="">
                                            <h2
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop2"
                                            >
                                              Upload Pdf
                                            </h2>
                                          </Link>
                                        )}
                                        <div
                                          className="modal fade comman_modal"
                                          id="staticBackdrop2"
                                          data-bs-backdrop="static"
                                          data-bs-keyboard="false"
                                          tabindex="-1"
                                          aria-labelledby="staticBackdropLabel"
                                          aria-hidden="true"
                                        >
                                          <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content border-0">
                                              <div className="modal-header">
                                                <h5
                                                  className="modal-title"
                                                  id="staticBackdropLabel"
                                                ></h5>
                                                <button
                                                  type="button"
                                                  className="btn-close"
                                                  data-bs-dismiss="modal"
                                                  aria-label="Close"
                                                  id="close"
                                                ></button>
                                              </div>
                                              <div className="modal-body ">
                                                <div class="row">
                                                  <div class=" form-design">
                                                    <div class="form-group   choose_file position-relative">
                                                      <>
                                                        {" "}
                                                        <span>Upload Pdf</span>
                                                        <label for="upload_pdf1">
                                                          <i class="fal fa-camera me-1"></i>
                                                          Choose File
                                                        </label>
                                                        <input
                                                          type="file"
                                                          accept=".png, .jpg, .jpeg"
                                                          class="form-control form-control1"
                                                          name="upload_pdf1"
                                                          id="upload_pdf1"
                                                          onChange={(e) =>
                                                            onFileSelection2(
                                                              e.target.files
                                                            )
                                                          }
                                                        />{" "}
                                                      </>
                                                    </div>
                                                  </div>
                                                  <div className="col-auto mx-auto my-3">
                                                    <Link
                                                      className="comman_btn"
                                                      to=""
                                                      onClick={() =>
                                                        uploadPdf()
                                                      }
                                                      data-bs-dismiss="modal"
                                                    >
                                                      Upload
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <form
                                    class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                                    onSubmit={handleSubmit(onSubmit)}
                                  >
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">My Feedback</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="feedback"
                                        name="feedback"
                                        {...register("feedback", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.feedback && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">Focus Of Session</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="focus_of_session"
                                        name="focus_of_session"
                                        {...register("focus_of_session", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.focus_of_session && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Progress Toward Goals
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="progress_toward_goals"
                                        name="progress_toward_goals"
                                        {...register("progress_toward_goals", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.progress_toward_goals && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">Safety Concerns</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="safety_concerns"
                                        name="safety_concerns"
                                        {...register("safety_concerns", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.safety_concerns && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6  clinical_documentation">
                                      <label for="">Plan</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="plan"
                                        name="plan"
                                        {...register("plan", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.plan && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6   choose_file position-relative">
                                      {getSession[monthIndex]?.sessions[
                                        sessionIndex
                                      ].clinician_signature ? (
                                        <div class="user_response_box me-3">
                                          <img
                                            src={
                                              getSession[monthIndex]?.sessions[
                                                sessionIndex
                                              ].clinician_signature
                                            }
                                            alt=""
                                          />
                                        </div>
                                      ) : (
                                        <>
                                          {" "}
                                          <span>Upload Signature </span>
                                          <label for="upload_video">
                                            <i class="fal fa-camera me-1"></i>
                                            Choose File
                                          </label>
                                          <input
                                            type="file"
                                            accept=".png, .jpg, .jpeg"
                                            class="form-control"
                                            name="upload_video"
                                            id="upload_video"
                                            onChange={(e) =>
                                              onFileSelection(e.target.files)
                                            }
                                          />{" "}
                                        </>
                                      )}
                                    </div>

                                    <div class="form-group mb-0 mt-4 col-12 mt-4">
                                      <div className="row justify-content-center">
                                        {!getSession[monthIndex].sessions[
                                          sessionIndex
                                        ].isSigned ? (
                                          <div className="col-4">
                                            <button
                                              class="comman_btn w-100 "
                                              type="submit"
                                              onClick={() => setType(1)}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div className="col-4">
                                          <button
                                            class="comman_btn w-100 "
                                            type="submit"
                                            onClick={() => setType(2)}
                                          >
                                            Sign
                                          </button>
                                        </div>
                                        {getSession[monthIndex].sessions[
                                          sessionIndex
                                        ].isSigned ? (
                                          <div className="col-4">
                                            <Link
                                              class=" text-center comman_btn w-100 "
                                              onClick={() => setType(3)}
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop"
                                              to=""
                                            >
                                              Addendum
                                            </Link>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      class="modal fade"
                                      id="staticBackdrop"
                                      data-bs-backdrop="static"
                                      data-bs-keyboard="false"
                                      tabindex="-1"
                                      aria-labelledby="staticBackdropLabel"
                                      aria-hidden="true"
                                    >
                                      <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content border-0">
                                          <div class="modal-body text-end">
                                            <button
                                              type="button"
                                              class="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                              id="close_reason"
                                            ></button>
                                            <div class="row">
                                              <div class="col-12 Update_modal_content text-center form-design ">
                                                <div class="form-group mb-4  clinical_documentation  mt-3 ">
                                                  <label for="">Comment</label>
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    id="reason"
                                                    name="reason"
                                                    onChange={(e) =>
                                                      setCompReason(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>

                                              <div className="col-12 mb-4 mt-2 d-flex justify-content-center">
                                                <button class="comman_btn ">
                                                  Save
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              ) : getSession[monthIndex]?.sessions[sessionIndex]
                                  .plan_type == 2 &&
                                getSession[monthIndex].sessions[sessionIndex]
                                  .number === 1 ? (
                                <div class="col-12 design_outter_comman border rounded mb-4">
                                  <div class="row comman_header justify-content-between">
                                    <div class="col">
                                      <h2>Clinical Documentation</h2>
                                    </div>
                                    <div className="col-auto">
                                      <div class="form-group  mb-0 choose_file position-relative">
                                        {getSession[monthIndex]?.sessions[
                                          sessionIndex
                                        ].pdf ? (
                                          <a
                                            href={`${getSession[monthIndex]?.sessions[sessionIndex].pdf}`}
                                            target="blank"
                                          >
                                            <h2>Download Pdf</h2>
                                          </a>
                                        ) : (
                                          <Link to="">
                                            <h2
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop2"
                                            >
                                              Upload Pdf
                                            </h2>
                                          </Link>
                                        )}
                                        <div
                                          className="modal fade comman_modal"
                                          id="staticBackdrop2"
                                          data-bs-backdrop="static"
                                          data-bs-keyboard="false"
                                          tabindex="-1"
                                          aria-labelledby="staticBackdropLabel"
                                          aria-hidden="true"
                                        >
                                          <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content border-0">
                                              <div className="modal-header">
                                                <h5
                                                  className="modal-title"
                                                  id="staticBackdropLabel"
                                                ></h5>
                                                <button
                                                  type="button"
                                                  className="btn-close"
                                                  data-bs-dismiss="modal"
                                                  aria-label="Close"
                                                  id="close"
                                                ></button>
                                              </div>
                                              <div className="modal-body ">
                                                <div class="row">
                                                  <div class=" form-design">
                                                    <div class="form-group   choose_file position-relative">
                                                      {getSession[monthIndex]
                                                        ?.sessions[sessionIndex]
                                                        .pdf ? (
                                                        <div class="user_response_box me-3">
                                                          <img
                                                            src={
                                                              getSession[
                                                                monthIndex
                                                              ]?.sessions[
                                                                sessionIndex
                                                              ].pdf
                                                            }
                                                            alt=""
                                                          />
                                                        </div>
                                                      ) : (
                                                        <>
                                                          {" "}
                                                          <span>
                                                            Upload Pdf
                                                          </span>
                                                          <label for="upload_pdf1">
                                                            <i class="fal fa-camera me-1"></i>
                                                            Choose File
                                                          </label>
                                                          <input
                                                            type="file"
                                                            accept=".png, .jpg, .jpeg"
                                                            class="form-control form-control1"
                                                            name="upload_pdf1"
                                                            id="upload_pdf1"
                                                            onChange={(e) =>
                                                              onFileSelection2(
                                                                e.target.files
                                                              )
                                                            }
                                                          />{" "}
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-auto mx-auto my-3">
                                                    <Link
                                                      className="comman_btn"
                                                      to=""
                                                      onClick={() =>
                                                        uploadPdf()
                                                      }
                                                      data-bs-dismiss="modal"
                                                    >
                                                      Upload
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <form
                                    class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                                    onSubmit={handleSubmit(onSubmit)}
                                  >
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What prompted you to start this journey?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="start_reason"
                                        name="start_reason"
                                        {...register("start_reason", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(e, "start_reason");
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.start_reason && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What are your goals in starting Passport
                                        Journeys?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="goals"
                                        name="goals"
                                        {...register("goals", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(e, "goals");
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.goals && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Have either of you been in
                                        counseling/therapy before either
                                        separately or together?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="therapy_history"
                                        name="therapy_history"
                                        {...register("therapy_history", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(e, "therapy_history");
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.therapy_history && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Orientation and Questions
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="focus_of_session"
                                        name="focus_of_session"
                                        {...register("focus_of_session", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(e, "focus_of_session");
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.focus_of_session && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">Safety Concerns</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="safety_concerns"
                                        name="safety_concerns"
                                        {...register("safety_concerns", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(e, "safety_concerns");
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.safety_concerns && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Observations and Assessment
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="observations_and_assessment"
                                        name="observations_and_assessment"
                                        {...register(
                                          "observations_and_assessment",
                                          {
                                            required: type === 1 ? false : true,
                                            onChange: (e) => {
                                              handleData(
                                                e,
                                                "observations_and_assessment"
                                              );
                                            },
                                          }
                                        )}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.observations_and_assessment && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6  clinical_documentation">
                                      <label for="">Plan</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="plan"
                                        name="plan"
                                        {...register("plan", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(e, "plan");
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.plan && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 choose_file position-relative">
                                      {getSession[monthIndex]?.sessions[
                                        sessionIndex
                                      ].clinician_signature ? (
                                        <div class="user_response_box me-3">
                                          <img
                                            src={
                                              getSession[monthIndex]?.sessions[
                                                sessionIndex
                                              ].clinician_signature
                                            }
                                            alt=""
                                          />
                                        </div>
                                      ) : (
                                        <>
                                          {" "}
                                          <span>Upload Signature </span>
                                          <label for="upload_video">
                                            <i class="fal fa-camera me-1"></i>
                                            Choose File
                                          </label>
                                          <input
                                            type="file"
                                            accept=".png, .jpg, .jpeg"
                                            class="form-control"
                                            name="upload_video"
                                            id="upload_video"
                                            onChange={(e) =>
                                              onFileSelection(e.target.files)
                                            }
                                          />{" "}
                                        </>
                                      )}
                                    </div>

                                    <div class="form-group col-6 mb-0 clinical_documentation mt-4">
                                      <label for="">
                                        Session Attendee Names
                                      </label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="attendees"
                                        name="attendees"
                                        {...register("attendees", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(e, "attendees");
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.attendees && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group mb-0 mt-4 col-12 mt-4">
                                      <div className="row justify-content-center">
                                        {!getSession[monthIndex].sessions[
                                          sessionIndex
                                        ].isSigned ? (
                                          <div className="col-4">
                                            <button
                                              class="comman_btn w-100 "
                                              type="submit"
                                              onClick={() => setType(1)}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div className="col-4">
                                          <button
                                            class="comman_btn w-100 "
                                            type="submit"
                                            onClick={() => setType(2)}
                                          >
                                            Sign
                                          </button>
                                        </div>
                                        {getSession[monthIndex].sessions[
                                          sessionIndex
                                        ].isSigned ? (
                                          <div className="col-4">
                                            <Link
                                              class=" text-center comman_btn w-100 "
                                              onClick={() => setType(3)}
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop"
                                              to=""
                                            >
                                              Addendum
                                            </Link>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      class="modal fade"
                                      id="staticBackdrop"
                                      data-bs-backdrop="static"
                                      data-bs-keyboard="false"
                                      tabindex="-1"
                                      aria-labelledby="staticBackdropLabel"
                                      aria-hidden="true"
                                    >
                                      <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content border-0">
                                          <div class="modal-body text-end">
                                            <button
                                              type="button"
                                              class="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                              id="close_reason"
                                            ></button>
                                            <div class="row">
                                              <div class="col-12 Update_modal_content text-center form-design ">
                                                <div class="form-group mb-4  clinical_documentation  mt-3 ">
                                                  <label for="">Comment</label>
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    id="reason"
                                                    name="reason"
                                                    onChange={(e) =>
                                                      setReason(e.target.value)
                                                    }
                                                  />
                                                </div>
                                              </div>

                                              <div className="col-12 mb-4 mt-2 d-flex justify-content-center">
                                                <button
                                                  class="comman_btn "
                                                  type="submit"
                                                >
                                                  Save
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              ) : getSession[monthIndex]?.sessions[sessionIndex]
                                  .plan_type === 2 &&
                                (getSession[monthIndex].sessions[sessionIndex]
                                  .number === 2 ||
                                  getSession[monthIndex].sessions[sessionIndex]
                                    .number === 3) ? (
                                <div class="col-12 design_outter_comman border rounded mb-4">
                                  <div class="row comman_header justify-content-between">
                                    <div class="col">
                                      <h2>Clinical Documentation</h2>
                                    </div>
                                    <div className="col-auto">
                                      <div class="form-group  mb-0 choose_file position-relative">
                                        {getSession[monthIndex]?.sessions[
                                          sessionIndex
                                        ].pdf ? (
                                          <a
                                            href={`${getSession[monthIndex]?.sessions[sessionIndex].pdf}`}
                                            target="blank"
                                          >
                                            <h2>Download Pdf</h2>
                                          </a>
                                        ) : (
                                          <Link to="">
                                            <h2
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop2"
                                            >
                                              Upload Pdf
                                            </h2>
                                          </Link>
                                        )}
                                        <div
                                          className="modal fade comman_modal"
                                          id="staticBackdrop2"
                                          data-bs-backdrop="static"
                                          data-bs-keyboard="false"
                                          tabindex="-1"
                                          aria-labelledby="staticBackdropLabel"
                                          aria-hidden="true"
                                        >
                                          <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content border-0">
                                              <div className="modal-header">
                                                <h5
                                                  className="modal-title"
                                                  id="staticBackdropLabel"
                                                ></h5>
                                                <button
                                                  type="button"
                                                  className="btn-close"
                                                  data-bs-dismiss="modal"
                                                  aria-label="Close"
                                                  id="close"
                                                ></button>
                                              </div>
                                              <div className="modal-body ">
                                                <div class="row">
                                                  <div class=" form-design">
                                                    <div class="form-group   choose_file position-relative">
                                                      {getSession[monthIndex]
                                                        ?.sessions[sessionIndex]
                                                        .pdf ? (
                                                        <div class="user_response_box me-3">
                                                          <img
                                                            src={
                                                              getSession[
                                                                monthIndex
                                                              ]?.sessions[
                                                                sessionIndex
                                                              ].pdf
                                                            }
                                                            alt=""
                                                          />
                                                        </div>
                                                      ) : (
                                                        <>
                                                          {" "}
                                                          <span>
                                                            Upload Pdf
                                                          </span>
                                                          <label for="upload_pdf1">
                                                            <i class="fal fa-camera me-1"></i>
                                                            Choose File
                                                          </label>
                                                          <input
                                                            type="file"
                                                            accept=".png, .jpg, .jpeg"
                                                            class="form-control form-control1"
                                                            name="upload_pdf1"
                                                            id="upload_pdf1"
                                                            onChange={(e) =>
                                                              onFileSelection2(
                                                                e.target.files
                                                              )
                                                            }
                                                          />{" "}
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-auto mx-auto my-3">
                                                    <Link
                                                      className="comman_btn"
                                                      to=""
                                                      onClick={() =>
                                                        uploadPdf()
                                                      }
                                                      data-bs-dismiss="modal"
                                                    >
                                                      Upload
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <form
                                    class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                                    onSubmit={handleSubmit(onSubmit)}
                                  >
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        How would you describe your
                                        mother-daughter relationship?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="mother_daughter_relationship"
                                        name="mother_daughter_relationship"
                                        {...register(
                                          "mother_daughter_relationship",
                                          {
                                            required: type === 1 ? false : true,
                                          }
                                        )}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.mother_daughter_relationship && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What are your goals for joining Passport
                                        Journeys?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="goals"
                                        name="goals"
                                        {...register("goals", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.goals && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What things work well within the
                                        relationship?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="well_within_relationship"
                                        name="well_within_relationship"
                                        {...register(
                                          "well_within_relationship",
                                          {
                                            required: type === 1 ? false : true,
                                          }
                                        )}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.well_within_relationship && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What would you like to see improve in
                                        the relationship?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="improve"
                                        name="improve"
                                        {...register("improve", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.improve && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What do you hope for in your
                                        mother-daughter relationship?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="hope"
                                        name="hope"
                                        {...register("hope", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.hope && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What do you identify as the biggest
                                        difficulty in the relationship?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="difficulty_in_relationship"
                                        name="difficulty_in_relationship"
                                        {...register(
                                          "difficulty_in_relationship",
                                          {
                                            required: type === 1 ? false : true,
                                          }
                                        )}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.difficulty_in_relationship && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What is the most important change you
                                        would like to achieve in going on this
                                        journey?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="change"
                                        name="change"
                                        {...register("change", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.change && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Do you currently see a therapist? If no,
                                        what support do you have in place?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="therapy_history"
                                        name="therapy_history"
                                        {...register("therapy_history", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.therapy_history && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What was helpful or not about your
                                        previous experience?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="previous_experience"
                                        name="previous_experience"
                                        {...register("previous_experience", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.previous_experience && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Is there other information you think is
                                        important for me to know before moving
                                        forward?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="other_info"
                                        name="other_info"
                                        {...register("other_info", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.other_info && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Review of red flags: (alcohol and/or
                                        drug use, active abuse in relationship,
                                        history of abuse, recent or unprocessed
                                        trauma)
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="red_flags_review"
                                        name="red_flags_review"
                                        {...register("red_flags_review", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.red_flags_review && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Mental health screen: (past psychiatric
                                        history, suicidality, violence,
                                        pertinent medical history){" "}
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="mental_health_screen"
                                        name="mental_health_screen"
                                        {...register("mental_health_screen", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.mental_health_screen && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">Social history</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="social_history"
                                        name="social_history"
                                        {...register("social_history", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.social_history && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Other pertinent information
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="other_pertinent_information"
                                        name="other_pertinent_information"
                                        {...register(
                                          "other_pertinent_information",
                                          {
                                            required: type === 1 ? false : true,
                                          }
                                        )}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.other_pertinent_information && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Observations: (mental status exam)
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="observations"
                                        name="observations"
                                        {...register("observations", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.observations && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Assessment: (suitability for this type
                                        of therapy)
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="assessment"
                                        name="assessment"
                                        {...register("assessment", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.assessment && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6  clinical_documentation">
                                      <label for="">Plan</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="plan"
                                        name="plan"
                                        {...register("plan", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.plan && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 choose_file position-relative">
                                      {getSession[monthIndex]?.sessions[
                                        sessionIndex
                                      ].clinician_signature ? (
                                        <div class="user_response_box me-3">
                                          <img
                                            src={
                                              getSession[monthIndex]?.sessions[
                                                sessionIndex
                                              ].clinician_signature
                                            }
                                            alt=""
                                          />
                                        </div>
                                      ) : (
                                        <>
                                          {" "}
                                          <span>Upload Signature </span>
                                          <label for="upload_video">
                                            <i class="fal fa-camera me-1"></i>
                                            Choose File
                                          </label>
                                          <input
                                            type="file"
                                            accept=".png, .jpg, .jpeg"
                                            class="form-control"
                                            name="upload_video"
                                            id="upload_video"
                                            onChange={(e) =>
                                              onFileSelection(e.target.files)
                                            }
                                          />{" "}
                                        </>
                                      )}
                                    </div>

                                    <div class="form-group col-6 mb-0 clinical_documentation mt-4">
                                      <label for="">
                                        Session Attendee Names
                                      </label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="attendees"
                                        name="attendees"
                                        {...register("attendees", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.attendees && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div
                                      class="form-group mb-0 mt-4 col-12 mt-4
                                    "
                                    >
                                      <div className="row justify-content-center">
                                        {!getSession[monthIndex].sessions[
                                          sessionIndex
                                        ].isSigned ? (
                                          <div className="col-4">
                                            <button
                                              class="comman_btn  w-100"
                                              type="submit"
                                              onClick={() => setType(1)}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div className="col-4">
                                          <button
                                            class="comman_btn  w-100"
                                            type="submit"
                                            onClick={() => setType(2)}
                                          >
                                            Sign
                                          </button>
                                        </div>
                                        {getSession[monthIndex].sessions[
                                          sessionIndex
                                        ].isSigned ? (
                                          <div className="col-4">
                                            <Link
                                              class=" text-center comman_btn w-100 "
                                              onClick={() => setType(3)}
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop"
                                              to=""
                                            >
                                              Addendum
                                            </Link>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      class="modal fade"
                                      id="staticBackdrop"
                                      data-bs-backdrop="static"
                                      data-bs-keyboard="false"
                                      tabindex="-1"
                                      aria-labelledby="staticBackdropLabel"
                                      aria-hidden="true"
                                    >
                                      <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content border-0">
                                          <div class="modal-body text-end">
                                            <button
                                              type="button"
                                              class="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                              id="close_reason"
                                            ></button>
                                            <div class="row">
                                              <div class="col-12 Update_modal_content text-center form-design ">
                                                <div class="form-group mb-4  clinical_documentation  mt-3 ">
                                                  <label for="">Comment</label>
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    id="reason"
                                                    name="reason"
                                                    onChange={(e) =>
                                                      setCompReason(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>

                                              <div className="col-12 mb-4 mt-2 d-flex justify-content-center">
                                                <button
                                                  class="comman_btn "
                                                  type="submit"
                                                >
                                                  Save
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              ) : getSession[monthIndex]?.sessions[sessionIndex]
                                  .plan_type == 2 &&
                                getSession[monthIndex].sessions[sessionIndex]
                                  .number === 4 ? (
                                <div class="col-12 design_outter_comman border rounded mb-4">
                                  <div class="row comman_header justify-content-between">
                                    <div class="col">
                                      <h2>Clinical Documentation</h2>
                                    </div>
                                    <div className="col-auto">
                                      <div class="form-group  mb-0 choose_file position-relative">
                                        {getSession[monthIndex]?.sessions[
                                          sessionIndex
                                        ].pdf ? (
                                          <a
                                            href={`${getSession[monthIndex]?.sessions[sessionIndex].pdf}`}
                                            target="blank"
                                          >
                                            <h2>Download Pdf</h2>
                                          </a>
                                        ) : (
                                          <Link to="">
                                            <h2
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop2"
                                            >
                                              Upload Pdf
                                            </h2>
                                          </Link>
                                        )}
                                        <div
                                          className="modal fade comman_modal"
                                          id="staticBackdrop2"
                                          data-bs-backdrop="static"
                                          data-bs-keyboard="false"
                                          tabindex="-1"
                                          aria-labelledby="staticBackdropLabel"
                                          aria-hidden="true"
                                        >
                                          <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content border-0">
                                              <div className="modal-header">
                                                <h5
                                                  className="modal-title"
                                                  id="staticBackdropLabel"
                                                ></h5>
                                                <button
                                                  type="button"
                                                  className="btn-close"
                                                  data-bs-dismiss="modal"
                                                  aria-label="Close"
                                                  id="close"
                                                ></button>
                                              </div>
                                              <div className="modal-body ">
                                                <div class="row">
                                                  <div class=" form-design">
                                                    <div class="form-group   choose_file position-relative">
                                                      {getSession[monthIndex]
                                                        ?.sessions[sessionIndex]
                                                        .pdf ? (
                                                        <div class="user_response_box me-3">
                                                          <img
                                                            src={
                                                              getSession[
                                                                monthIndex
                                                              ]?.sessions[
                                                                sessionIndex
                                                              ].pdf
                                                            }
                                                            alt=""
                                                          />
                                                        </div>
                                                      ) : (
                                                        <>
                                                          {" "}
                                                          <span>
                                                            Upload Pdf
                                                          </span>
                                                          <label for="upload_pdf1">
                                                            <i class="fal fa-camera me-1"></i>
                                                            Choose File
                                                          </label>
                                                          <input
                                                            type="file"
                                                            accept=".png, .jpg, .jpeg"
                                                            class="form-control form-control1"
                                                            name="upload_pdf1"
                                                            id="upload_pdf1"
                                                            onChange={(e) =>
                                                              onFileSelection2(
                                                                e.target.files
                                                              )
                                                            }
                                                          />{" "}
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-auto mx-auto my-3">
                                                    <Link
                                                      className="comman_btn"
                                                      to=""
                                                      onClick={() =>
                                                        uploadPdf()
                                                      }
                                                      data-bs-dismiss="modal"
                                                    >
                                                      Upload
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <form
                                    class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                                    onSubmit={handleSubmit(onSubmit)}
                                  >
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Clinician Feedback:Is the pair a good
                                        fit for Passport Journeys currently? If
                                        no, what recommendations were made?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="feedback"
                                        name="feedback"
                                        {...register("feedback", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.feedback && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Feedback from mother-daughter pair
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="feedback_from_pair"
                                        name="feedback_from_pair"
                                        {...register("feedback_from_pair", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.feedback_from_pair && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">Goals for treatment</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="goals"
                                        name="goals"
                                        {...register("goals", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.goals && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">Safety Concerns</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="safety_concerns"
                                        name="safety_concerns"
                                        {...register("safety_concerns", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.safety_concerns && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Other pertinent information
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="other_pertinent_information"
                                        name="other_pertinent_information"
                                        {...register(
                                          "other_pertinent_information",
                                          {
                                            required: type === 1 ? false : true,
                                          }
                                        )}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.other_pertinent_information && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6  clinical_documentation">
                                      <label for="">Plan</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="plan"
                                        name="plan"
                                        {...register("plan", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.plan && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 choose_file position-relative">
                                      {getSession[monthIndex]?.sessions[
                                        sessionIndex
                                      ].clinician_signature ? (
                                        <div class="user_response_box me-3">
                                          <img
                                            src={
                                              getSession[monthIndex]?.sessions[
                                                sessionIndex
                                              ].clinician_signature
                                            }
                                            alt=""
                                          />
                                        </div>
                                      ) : (
                                        <>
                                          {" "}
                                          <span>Upload Signature </span>
                                          <label for="upload_video">
                                            <i class="fal fa-camera me-1"></i>
                                            Choose File
                                          </label>
                                          <input
                                            type="file"
                                            accept=".png, .jpg, .jpeg"
                                            class="form-control"
                                            name="upload_video"
                                            id="upload_video"
                                            onChange={(e) =>
                                              onFileSelection(e.target.files)
                                            }
                                          />{" "}
                                        </>
                                      )}
                                    </div>

                                    <div class="form-group col-6 mb-0 clinical_documentation mt-4">
                                      <label for="">
                                        Session Attendee Names
                                      </label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="attendees"
                                        name="attendees"
                                        {...register("attendees", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned ? false : false
                                        }
                                      />
                                      {errors?.attendees && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group mb-0 mt-4 col-12 mt-4">
                                      <div className="row justify-content-center">
                                        {!getSession[monthIndex].sessions[
                                          sessionIndex
                                        ].isSigned ? (
                                          <div className="col-4">
                                            <button
                                              class="comman_btn w-100"
                                              type="submit"
                                              onClick={() => setType(1)}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div className="col-4">
                                          <button
                                            class="comman_btn w-100"
                                            type="submit"
                                            onClick={() => setType(2)}
                                          >
                                            Sign
                                          </button>
                                        </div>
                                        {getSession[monthIndex].sessions[
                                          sessionIndex
                                        ].isSigned ? (
                                          <div className="col-4">
                                            <Link
                                              class=" text-center comman_btn w-100 "
                                              onClick={() => setType(3)}
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop"
                                              to=""
                                            >
                                              Addendum
                                            </Link>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      class="modal fade"
                                      id="staticBackdrop"
                                      data-bs-backdrop="static"
                                      data-bs-keyboard="false"
                                      tabindex="-1"
                                      aria-labelledby="staticBackdropLabel"
                                      aria-hidden="true"
                                    >
                                      <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content border-0">
                                          <div class="modal-body text-end">
                                            <button
                                              type="button"
                                              class="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                              id="close_reason"
                                            ></button>
                                            <div class="row">
                                              <div class="col-12 Update_modal_content text-center form-design ">
                                                <div class="form-group mb-4  clinical_documentation  mt-3 ">
                                                  <label for="">Comment</label>
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    id="reason"
                                                    name="reason"
                                                    onChange={(e) =>
                                                      setCompReason(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>

                                              <div className="col-12 mb-4 mt-2 d-flex justify-content-center">
                                                <button
                                                  class="comman_btn "
                                                  type="submit"
                                                >
                                                  Save
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              ) : (
                                <ClinicianDocDummy/>
                              )}
                              </>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClinicianUserDetails;
