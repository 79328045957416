import React, { useEffect, useState } from "react";
import { getContents } from "../apiServices/clinicianPanelHttpServices/supportHttpService/clinicianSupportHttpService";
import WebFooter from "./commonComponent/webFooter";
import WebHeader from "./commonComponent/webHeader";

function Treatment() {
  return (
    <>
      <div className="web-body">
        <WebHeader />

        <div className="row justify-content-center ">
          <div className="col-10 mb-4 mt-5">
            <div className="row justify-content-center ">
              <div className="col-auto">
                <h2 style={{ fontSize: "30px" }}>
                  Telehealth Client Consent Form{" "}
                </h2>
              </div>
            </div>
            <div className="row mx-1">
              <div className="col-12 all_page_text py-4">
                <p>
                  I hereby give my full consent for myself and/or my minor child
                  to receive virtual services with Passport Journeys, LLC as
                  part of my/my family’s services. I understand that telehealth
                  is the practice of delivering clinical health care services
                  via technology assisted media or other electronic means
                  between a practitioner and a client who are located in two
                  different locations. <br /> I understand the following with
                  respect to telehealth:
                </p>
                <ol>
                  <li>
                    <p>
                      I understand that there are risk and consequences
                      associated with telehealth, including but not limited to,
                      disruption of transmission by technology failures,
                      interruption and/or breaches of confidentiality by
                      unauthorized persons, and/or limited ability to respond to
                      emergencies.
                    </p>
                  </li>
                  <li>
                    <p>
                      I understand that there will be no recording of any of the
                      online sessions by either party. All information disclosed
                      within sessions and written records pertaining to those
                      sessions are confidential and may not be disclosed to
                      anyone without written authorization, except where the
                      disclosure is permitted and/or required by law.
                    </p>
                  </li>

                  <li>
                    <p>
                      I understand that the privacy laws that protect the
                      confidentiality of my protected health information (PHI)
                      also apply to telehealth unless an exception to
                      confidentiality applies (i.e. mandatory reporting of
                      child, elder, or vulnerable adult abuse; danger to self or
                      others; or I raise mental/emotional health as an issue in
                      a legal proceeding).
                    </p>
                  </li>
                  <li>
                    <p>
                      I understand that if I am having suicidal or homicidal
                      thoughts, actively experiencing psychotic symptoms, or
                      experiencing a mental health crisis that cannot be
                      resolved remotely, it may be determined that telehealth
                      services are not appropriate and/or a higher level of care
                      is required.
                    </p>
                  </li>

                  <li>
                    <p>
                      I understand that in the event of a technical failure, the
                      following back up plan will be enacted: my clinician will
                      attempt to call me 5 minutes after the technical failure
                      to resume the appointment. If contact is unable to be
                      made, my clinician or a representative of Passport
                      Journeys, LLC will call me to reschedule.
                    </p>
                  </li>
                  <li>
                    <p>
                      I understand that my clinician will make every effort to
                      maintain confidentiality by working from a quiet, secure,
                      and confidential location and it is my responsibility to
                      assure confidentiality by participating from a quiet,
                      secure, and confidential location as well. I understand
                      that the clinician will need to reschedule the session if
                      a quiet, secure, and confidential location is not
                      available.
                    </p>
                  </li>

                  <li>
                    <p>
                      I understand that my clinician may need to contact my
                      emergency contact and/or appropriate authorities in case
                      of an emergency. I understand that my clinician will need
                      to know my location in case of an emergency.
                    </p>
                  </li>

                  <li>
                    <p>
                      I understand that if there are custody orders in place, a
                      copy of the divorce decree must be provided so that the
                      parent's right to consent for treatment can be verified.
                      An emailed copy must be sent to admin@
                      passportjourneysapp.com before the first appointment.
                    </p>
                  </li>
                </ol>

                <div className="row justify-content-center ">
                  <div className="col-auto">
                    <h2 style={{ fontSize: "30px" }}>Emergency Protocols: </h2>
                  </div>
                </div>

                <p>
                  I agree to inform the clinician of any changes to my location
                  or environment prior to/or at beginning of session. I
                  understand that the clinician or Passport Journeys, LLC also
                  needs a contact person who they may contact on my behalf in a
                  life-threatening emergency only. This person will only be
                  contacted to go to my location or take me to the hospital in
                  the event of an emergency. In case of an emergency, I
                  understand that I will need to update my home address or, if
                  sessions typically take place at another location (such as my
                  office, or the home of another person), that I should provide
                  my clinician with that address.
                </p>
                <p>
                  I have read the information provided above and discussed it
                  with my clinician. I understand the information contained in
                  this form and all of my questions have been answered to my
                  satisfaction. I agree to inform the clinician of any changes
                  to my location or environment prior to/or at beginning of
                  session.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <WebFooter />
      </div>
    </>
  );
}

export default Treatment;
