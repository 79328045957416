import React, { useEffect, useState } from "react";
import { getSessionData } from "../../apiServices/clinicianPanelHttpServices/loginHttpService/clinicianLoginHttpService";
import { useParams } from "react-router-dom";
import moment from "moment";
import { getUserData } from "../../apiServices/userHttpService/adminUserHttpService";

function AdminNppForm() {
  const [upcomingData, setUpcomingData] = useState("");
  let { id, type } = useParams();
  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    const { data } = await getUserData(id);
    if (!data.error) {
      setUpcomingData(data.results.user);
    }
  };
  return (
    <>
      <div
        style={{ fontFamily: '"Josefin Sans", sans-serif', background: "#fff" }}
      >
        <div className="container pt-5">
          <div className="telehealth-client-main" style={{ padding: 0 }}>
            <div
              className="telehealth-client-top"
              style={{ textAlign: "center", paddingBottom: "30px" }}
            >
              <img
                style={{
                  display: "block",
                  maxWidth: "150px",
                  margin: "0 auto",
                }}
                src="https://passportjourneysapp.com/assets/img/mini-logo.png"
                alt=""
              />
              <h1
                style={{
                  fontSize: "21px",
                  color: "#000",
                  margin: "30px 0 10px",
                  fontFamily: '"Josefin Sans", sans-serif',
                }}
              >
                NOTICE OF PRIVACY PRACTICES (NPP)
              </h1>
              <span
                style={{
                  fontSize: "15px",
                  fontFamily: '"Josefin Sans", sans-serif',
                }}
              >
                EFFECTIVE DATE OF THIS NOTICE - October 10, 2022
              </span>
            </div>
            <div className="content-part">
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                THIS NOTICE DESCRIBES HOW HEALTH INFORMATION MAY BE USED AND
                DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE
                REVIEW IT CAREFULLY.
              </p>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                1. Your medical records are used to provide treatment, to bill
                and receive payments, to conduct healthcare operations and to
                create a research model for a published framework.
              </p>
              <ul style={{ listStyle: "circle", marginBottom: "30px" }}>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: 0,
                    }}
                  >
                    Examples of these activities include but are not limited to:
                    review of treatment records to ensure appropriate care,
                    electronic or mail delivery of billing for treatment to you
                    or other authorized payers, appointment reminder telephone
                    calls, emails, and/or text messages, and records review to
                    ensure completeness and quality of care. Progress notes are
                    part of your medical record.
                  </p>
                </li>
              </ul>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                2. Use or disclosure of your personal information is limited as
                outlined in Section 1, with the following exceptions:
              </p>
              <ul style={{ listStyle: "circle", marginBottom: "30px" }}>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: 0,
                    }}
                  >
                    Federal and State laws require me to report abuse or neglect
                    of (1) a child, (2) an adult 65 years or older, or (3) an
                    individual with a disability, to an appropriate protective
                    agency.
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: 0,
                    }}
                  >
                    We are required to report any credible threats to your
                    safety or the safety of others to an appropriate law
                    enforcement agency.{" "}
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: 0,
                    }}
                  >
                    Service Providers - We may employ third-party companies and
                    individuals to facilitate our Platform, to perform certain
                    tasks which are related to the Platform, or to provide
                    audit, legal, operational, or other services for us. These
                    tasks include, but are not limited to, customer service,
                    technical maintenance, monitoring, email management and
                    communication, database management, billing and payment
                    processing, reporting and analytics. We will share with them
                    only the minimum necessary information to perform their task
                    for us and only after entering into appropriate
                    confidentiality agreements.
                  </p>
                </li>
              </ul>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                3. Any information we disclose as described in Section 2 will be
                limited to the minimum necessary. Any reports we make will be
                disclosed to you. We are available to answer any questions you
                may have about these exceptions. We will let you know promptly
                if a breach occurs that may have compromised the privacy or
                security of your information.
              </p>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                4. You may request a copy or summary of your records be
                disclosed to yourself or someone else. Your request must be made
                in writing to your assigned clinician, clearly identify the
                person or entity you want the information released to, specify
                the information you want disclosed, and include the expiration
                date of the authorization.
              </p>
              <ul style={{ listStyle: "circle", marginBottom: "30px" }}>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: 0,
                    }}
                  >
                    You may revoke any authorization at any time.{" "}
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: 0,
                    }}
                  >
                    We will respond to a disclosure request within 30 days.{" "}
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: 0,
                    }}
                  >
                    You may request corrections to your records.
                  </p>
                </li>
              </ul>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                5. You may request that we restrict uses and disclosures
                outlined in Section 1. However, we are not required to agree to
                the restrictions. If an agreement is made to restrict use or
                disclosure, we will be bound by such restriction until revoked
                by you or when disclosure is required by law or in an emergency.
              </p>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                6. As early users of this new teletherapy platform, your intake
                questionnaire data and ongoing relationship assessment data will
                be deidentified and used in research cases, studies and
                potential publications.{" "}
              </p>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                7. Security of Information - While using any Internet-based
                service carries inherent security risks that cannot be 100%
                prevented, our systems, infrastructure, encryption technology,
                operation and processes are all designed, built, and maintained
                with your security and privacy in mind. We apply industry
                standards and best practices to prevent any unauthorized access,
                use, and disclosure. We comply with all applicable federal laws,
                state laws, and regulations regarding data privacy.
              </p>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                8. If you wish to file a complaint about privacy-related issues,
                you may contact the Secretary of the Department of Health and
                Human Services, 200 Independence Avenue S.W., Washington D.C.,
                20201. We will not retaliate against you for filing a complaint.
              </p>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                9. This Notice, including any modifications or amendments, will
                be available within the Passport Journeys App. You may also
                request a paper copy at any time.
              </p>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                10. If you have any questions or complaints regarding this
                Notice or other privacy concerns, you may call our office at
                1800-957-6533 or email our Privacy Officer -{" "}
                <a
                  style={{ color: "blue" }}
                  href="mailto:privacy@passportjourneysapp.com"
                >
                  privacy@passportjourneysapp.com
                </a>
              </p>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "60px",
                  marginTop: "30px",
                }}
              >
                Last Updated and Effective: October 10, 2022
              </p>
              <h1
                style={{
                  fontSize: "21px",
                  color: "#000",
                  margin: "30px 0 40px",
                  textAlign: "center",
                  fontFamily: '"Josefin Sans", sans-serif',
                }}
              >
                Acknowledgement of Receipt of NOTICE OF PRIVACY PRACTICES (NPP)
              </h1>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                Under the Health Insurance Portability and Accountability Act of
                1996 (HIPAA), you have certain rights regarding the use and
                disclosure of your protected health information.{" "}
              </p>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                BY SIGNING BELOW, I AM AGREEING THAT I HAVE READ, UNDERSTOOD AND
                AGREE TO THE ITEMS CONTAINED IN THIS DOCUMENT.
              </p>
              <div
                className="Bottom_part"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginTop: "70px",
                  alignItems: "end",
                }}
              >
                <div
                  className="Bottom_part_box"
                  style={{ width: "50%", marginBottom: "50px" }}
                >
                  <span
                    style={{
                      fontWeight: 400,
                      display: "block",
                      width: "50%",
                      borderBottom: "1px solid #3d3d3d",
                    }}
                  >
                    <img
                      src={
                        type === "Mother"
                          ? upcomingData?.mother_npp?.signature1
                          : upcomingData?.daughter_npp?.signature1
                      }
                      style={{ width: "50%" }}
                      alt=""
                    />
                  </span>
                  <h3
                    style={{
                      fontSize: "17px",
                      color: "#000",
                      margin: "15px 0 0",
                      fontFamily: '"Josefin Sans", sans-serif',
                    }}
                  >
                    Signature
                  </h3>
                </div>
                <div
                  className="Bottom_part_box"
                  style={{ width: "50%", marginBottom: "50px" }}
                >
                  <span
                    style={{
                      fontWeight: 400,
                      display: "block",
                      width: "50%",
                      borderBottom: "1px solid #3d3d3d",
                    }}
                  >
                    {type === "Mother"
                      ? moment(upcomingData?.mother_npp?.date1).format("LL")
                      : moment(upcomingData?.daughter_npp?.date1).format("LL")}
                  </span>
                  <h3
                    style={{
                      fontSize: "17px",
                      color: "#000",
                      margin: "15px 0 0",
                      fontFamily: '"Josefin Sans", sans-serif',
                    }}
                  >
                    Date
                  </h3>
                </div>
                <div
                  className="Bottom_part_box"
                  style={{ width: "50%", marginBottom: "50px" }}
                >
                  <span
                    style={{
                      fontWeight: 400,
                      display: "block",
                      width: "50%",
                      borderBottom: "1px solid #3d3d3d",
                    }}
                  >
                    {type === "Mother"
                      ? upcomingData?.mother_npp?.name1
                      : upcomingData?.daughter_npp?.name1}
                  </span>
                  <h3
                    style={{
                      fontSize: "17px",
                      color: "#000",
                      margin: "15px 0 0",
                      fontFamily: '"Josefin Sans", sans-serif',
                    }}
                  >
                    Printed Name
                  </h3>
                </div>
              </div>
              <h2
                style={{
                  fontSize: "21px",
                  color: "#000",
                  margin: "50px 0 50px",
                  textAlign: "center",
                  fontFamily: '"Josefin Sans", sans-serif',
                }}
              >
                PRACTICE POLICIES
              </h2>
              <h3
                style={{
                  fontSize: "18px",
                  color: "#000",
                  textAlign: "left",
                  marginBottom: "20px",
                  fontFamily: '"Josefin Sans", sans-serif',
                }}
              >
                Fees:
              </h3>
              <ul style={{ marginBottom: "30px" }}>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: "15px",
                    }}
                  >
                    Month 1 Intake Appointments - $280 per Mother-Daughter Pair{" "}
                  </p>
                  <ul style={{ listStyle: "circle", marginBottom: "20px" }}>
                    <li style={{ marginBottom: "5px" }}>
                      <p
                        style={{
                          fontSize: "16px",
                          lineHeight: "15px",
                          marginBottom: 0,
                        }}
                      >
                        Month 1 Intake Appointments - $280 per Mother-Daughter
                        Pair
                      </p>
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      <p
                        style={{
                          fontSize: "16px",
                          lineHeight: "15px",
                          marginBottom: 0,
                        }}
                      >
                        Intake Individual consultation (60 minutes)
                      </p>
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      <p
                        style={{
                          fontSize: "16px",
                          lineHeight: "15px",
                          marginBottom: 0,
                        }}
                      >
                        Treatment planning consultation (30 minutes){" "}
                      </p>
                    </li>
                  </ul>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: "15px",
                    }}
                  >
                    Monthly app subscription - $220 per Mother-Daughter Pair{" "}
                  </p>
                  <ul style={{ listStyle: "circle", marginBottom: "20px" }}>
                    <li style={{ marginBottom: "5px" }}>
                      <p
                        style={{
                          fontSize: "16px",
                          lineHeight: "15px",
                          marginBottom: 0,
                        }}
                      >
                        Paired therapy – 2 live sessions (45 minutes each)
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <h3
                style={{
                  fontSize: "18px",
                  color: "#000",
                  textAlign: "left",
                  marginBottom: "20px",
                  fontFamily: '"Josefin Sans", sans-serif',
                }}
              >
                Attendance:
              </h3>
              <ul style={{ marginBottom: "30px" }}>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: "15px",
                    }}
                  >
                    No-show, non-cancel: if there is a no-show/non-cancel you
                    will lose that session for the month.
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: "15px",
                    }}
                  >
                    If there are 3 consecutive months of inactivity and/or
                    no-shows/non-cancels: the treatment contract will be
                    terminated and your monthly subscription will be closed.
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: "15px",
                    }}
                  >
                    Cancellation policy: at least 24 business hours’ notice is
                    required for rescheduling appointments.
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: "15px",
                    }}
                  >
                    If you are late to an appointment, the session will still
                    end at the scheduled session time.
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: "15px",
                    }}
                  >
                    • Both participants are required to be present once the
                    therapy process has started.
                  </p>
                </li>
              </ul>
              <h3
                style={{
                  fontSize: "18px",
                  color: "#000",
                  textAlign: "left",
                  marginBottom: "20px",
                  fontFamily: '"Josefin Sans", sans-serif',
                }}
              >
                INSURANCE:
              </h3>
              <ul style={{ marginBottom: "30px" }}>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: "15px",
                    }}
                  >
                    Currently, we are not accepting insurance.
                  </p>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      marginBottom: "15px",
                    }}
                  >
                    All intake appointments and monthly subscriptions through
                    the app will be self-pay, without the ability to provide a
                    super bill for reimbursement.{" "}
                  </p>
                  <ul style={{ listStyle: "circle", marginBottom: "20px" }}>
                    <li style={{ marginBottom: "5px" }}>
                      <p
                        style={{
                          fontSize: "16px",
                          lineHeight: "15px",
                          marginBottom: 0,
                        }}
                      >
                        As our platform expands, we will be striving for more
                        financial assistance and insurance capabilities.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "60px",
                  marginTop: "50px",
                }}
              >
                Last Updated and Effective: October 10, 2022
              </p>
              <h1
                style={{
                  fontSize: "21px",
                  color: "#000",
                  margin: "30px 0 40px",
                  textAlign: "center",
                  fontFamily: '"Josefin Sans", sans-serif',
                }}
              >
                Acknowledgement of Receipt of Practice Policies
              </h1>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                Under the Health Insurance Portability and Accountability Act of
                1996 (HIPAA), you have certain rights regarding the use and
                disclosure of your protected health information.{" "}
              </p>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "29px",
                  marginBottom: "20px",
                }}
              >
                BY SIGNING BELOW, I AM AGREEING THAT I HAVE READ, UNDERSTOOD AND
                AGREE TO THE ITEMS CONTAINED IN THIS DOCUMENT.
              </p>
              <div
                className="Bottom_part"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginTop: "70px",
                  alignItems: "end",
                }}
              >
                <div
                  className="Bottom_part_box"
                  style={{ width: "50%", marginBottom: "50px" }}
                >
                  <span
                    style={{
                      fontWeight: 400,
                      display: "block",
                      width: "50%",
                      borderBottom: "1px solid #3d3d3d",
                    }}
                  >
                    <img
                      src={
                        type === "Mother"
                          ? upcomingData?.mother_npp?.signature2
                          : upcomingData?.daughter_npp?.signature2
                      }
                      style={{ width: "50%" }}
                      alt=""
                    />
                  </span>
                  <h3
                    style={{
                      fontSize: "17px",
                      color: "#000",
                      margin: "15px 0 0",
                      fontFamily: '"Josefin Sans", sans-serif',
                    }}
                  >
                    Signature
                  </h3>
                </div>
                <div
                  className="Bottom_part_box"
                  style={{ width: "50%", marginBottom: "50px" }}
                >
                  <span
                    style={{
                      fontWeight: 400,
                      display: "block",
                      width: "50%",
                      borderBottom: "1px solid #3d3d3d",
                    }}
                  >
                    {type === "Mother"
                      ? moment(upcomingData?.mother_npp?.date2).format("LL")
                      : moment(upcomingData?.daughter_npp?.date2).format("LL")}
                  </span>
                  <h3
                    style={{
                      fontSize: "17px",
                      color: "#000",
                      margin: "15px 0 0",
                      fontFamily: '"Josefin Sans", sans-serif',
                    }}
                  >
                    Date
                  </h3>
                </div>
                <div
                  className="Bottom_part_box"
                  style={{ width: "50%", marginBottom: "50px" }}
                >
                  <span
                    style={{
                      fontWeight: 400,
                      display: "block",
                      width: "50%",
                      borderBottom: "1px solid #3d3d3d",
                    }}
                  >
                    {type === "Mother"
                      ? upcomingData?.mother_npp?.name2
                      : upcomingData?.daughter_npp?.name2}
                  </span>
                  <h3
                    style={{
                      fontSize: "17px",
                      color: "#000",
                      margin: "15px 0 0",
                      fontFamily: '"Josefin Sans", sans-serif',
                    }}
                  >
                    Printed Name
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminNppForm;
