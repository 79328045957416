import React, { useEffect, useState } from "react";
import AdminHeader from "../commonComponent/adminHeader";
import AdminSidebar from "../commonComponent/adminSidebar";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  getTransactions,
  getUnRenewed,
} from "../../apiServices/supportHttpService/adminSupportHttpService";

function TransactionManagement() {
  const [from, setFrom] = useState("");
  const [till, setTill] = useState("");
  const [type, setType] = useState("");
  const [transaction, setTransaction] = useState({
    columns: [
      {
        label: "S.No.",
        field: "sn",
        sort: "asc",
        width: 150,
      },

      {
        label: "User Name",
        field: "name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Subscription Plan Name",
        field: "plan",
        sort: "asc",
        width: 150,
      },
      {
        label: "Pricing",
        field: "pricing",
        sort: "asc",
        width: 150,
      },
      {
        label: "Transaction Date & Time",
        field: "date",
        sort: "asc",
        width: 100,
      },
      {
        label: "Plan Validity",
        field: "validity",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });

  const [renewal, setRenewals] = useState({
    columns: [
      {
        label: "S.No.",
        field: "sn",
        sort: "asc",
        width: 150,
      },

      {
        label: "User Name",
        field: "name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Subscription Plan Name",
        field: "plan",
        sort: "asc",
        width: 150,
      },
      {
        label: "Pricing",
        field: "pricing",
        sort: "asc",
        width: 150,
      },

      {
        label: "Plan Validity",
        field: "validity",
        sort: "asc",
        width: 100,
      },
      {
        label: "Plan Started",
        field: "start",
        sort: "asc",
        width: 100,
      },
      {
        label: "Plan Expiry",
        field: "expiry",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });

  const [expired, setExpired] = useState({
    columns: [
      {
        label: "S.No.",
        field: "sn",
        sort: "asc",
        width: 150,
      },
      {
        label: "User Name",
        field: "name",
        sort: "asc",
        width: 150,
      },

      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 100,
      },
      {
        label: "Registration Date",
        field: "date",  
        sort: "asc",
        width: 100,
      },
      {
        label: "Plan Purchased",
        field: "plan",
        sort: "asc",
        width: 100,
      },
      {
        label: "Plan Pricing",
        field: "pricing",
        sort: "asc",
        width: 100,
      },
      {
        label: "Plan duration",
        field: "duration",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });

  useEffect(() => {
    getTransactionListing();
    getExpiredListing();
  }, []);

  const getTransactionListing = async (type) => {
    const formData = {
      from: from,
      till: till,
      type: type,
    };

    const { data } = await getTransactions(formData);

    if (!data.error) {
      const newRows = [];
      console.log(data);
      if (type === "Renew") {
        data.results.transactions.map((list, index) => {
          const returnData = {};

          returnData.sn = index + 1;

          returnData.name = list.user?.full_name;
          returnData.plan = list.plan.plan_name;
          returnData.pricing = list.plan.plan_price;
          returnData.date = moment(list.createdAt).format("L");
          returnData.validity = list.plan.plan_duration;
          returnData.start = moment(list.valid_from).format("L");
          returnData.expiry = moment(list.valid_till).format("L");

          newRows.push(returnData);
        });

        setRenewals({ ...renewal, rows: newRows });
      } else {
        data.results.transactions.map((list, index) => {
          const returnData = {};

          returnData.sn = index + 1;

          returnData.name = list.user?.full_name;
          returnData.plan = list.plan.plan_name;
          returnData.pricing = list.plan.plan_price;
          returnData.date = moment(list.createdAt).format("L");
          returnData.validity = list.plan.plan_duration;

          newRows.push(returnData);
        });

        setTransaction({ ...transaction, rows: newRows });
      }
    }
  };

  const getExpiredListing = async () => {
    const formData = {
      from: from,
      till: till,
      page: 1,
      pageSize: 10,
    };

    const { data } = await getUnRenewed(formData);
    if (!data.error) {
      const newRows = [];
      console.log(data, "opopop");
      data.results.users.map((list, index) => {
        const returnData = {};
        returnData.sn = index + 1;
        returnData.name = list.full_name;
        returnData.type = list.type;
        returnData.mobile_number = list.phone_number;
        returnData.email = list.email;
        returnData.date = moment(list.createdAt).format("L");
        returnData.plan = list?.planId?.plan_name;
        returnData.pricing = list?.planId?.plan_price;
        returnData.duration = list?.planId?.plan_duration;

        returnData.action = (
          <>
            <Link
              className="comman_btn table_viewbtn"
              to={`/admin/user-details/${list._id}`}
            >
              View
            </Link>
          </>
        );
        newRows.push(returnData);
      });

      setExpired({ ...expired, rows: newRows });
    }
  };

  return (
    <>
      {" "}
      <div className="admin_main">
        <AdminSidebar />
        <div className="admin_main_inner">
          <AdminHeader />
          <div className="admin_panel_data height_adjust">
            <div className="row transaction-management justify-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 design_outter_comman recent_orders shadow">
                    <div className="row comman_header justify-content-between">
                      <div className="col-auto">
                        <h2>Transaction Management</h2>
                      </div>
                      <div className="col-3">
                        <form className="form-design">
                          <div className="form-group mb-0 position-relative icons_set">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              name="name"
                              id="name"
                            />
                            <i className="far fa-search"></i>
                          </div>
                        </form>
                      </div>
                    </div>
                    <form
                      className="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                      action=""
                    >
                      <div className="form-group mb-0 col-5">
                        <label for="">From</label>
                        <input
                          type="datetime-local"
                          className="form-control"
                          onChange={(e) => setFrom(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-0 col-5">
                        <label for="">To</label>
                        <input
                          type="datetime-local"
                          className="form-control"
                          onChange={(e) => setTill(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-0 col-auto">
                        <Link
                          className="comman_btn "
                          onClick={() => getTransactionListing()}
                          to=""
                        >
                          Search
                        </Link>
                      </div>
                    </form>
                    <div className="row p-4">
                      <div className="col-12 p-0 help-support-table border border-2 border-bottom-0 rounded overflow-hidden border-light">
                        <nav>
                          <div
                            className="nav nav-tabs justify-content-center border-0"
                            id="nav-tab"
                            role="tablist"
                          >
                            <button
                              className="nav-link active"
                              style={{ width: "33.33%" }}
                              id="nav-home-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-home"
                              type="button"
                              role="tab"
                              aria-controls="nav-home"
                              aria-selected="true"
                              onClick={() => setType("")}
                            >
                              All Transactions
                            </button>
                            <button
                              className="nav-link"
                              style={{ width: "33.33%" }}
                              id="nav-profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-profile"
                              type="button"
                              role="tab"
                              aria-controls="nav-profile"
                              aria-selected="false"
                              onClick={() => {
                                getTransactionListing("Renew");
                                setType("Renew");
                              }}
                            >
                              Renewals
                            </button>
                            <button
                              className="nav-link "
                              style={{ width: "33.33%" }}
                              id="nav-renew-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-renew"
                              type="button"
                              role="tab"
                              aria-controls="nav-renew"
                              aria-selected="true"
                            >
                              Un-Renewed Plans
                            </button>
                          </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="nav-home"
                            role="tabpanel"
                            aria-labelledby="nav-home-tab"
                          >
                            <div className="row">
                              <div className="col-12 comman_table_design px-0">
                                <div className="table-responsive">
                                  <MDBDataTable
                                    bordered
                                    hover
                                    data={transaction}
                                    noBottomColumns
                                    sortable
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-profile"
                            role="tabpanel"
                            aria-labelledby="nav-profile-tab"
                          >
                            <div className="row">
                              <div className="col-12 comman_table_design px-0">
                                <div className="table-responsive">
                                  <MDBDataTable
                                    bordered
                                    hover
                                    data={renewal}
                                    noBottomColumns
                                    sortable
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="nav-renew"
                            role="tabpanel"
                            aria-labelledby="nav-renew-tab"
                          >
                            <div className="row">
                              <div className="col-12 comman_table_design px-0">
                                <div className="table-responsive">
                                  <MDBDataTable
                                    bordered
                                    hover
                                    data={expired}
                                    noBottomColumns
                                    sortable
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TransactionManagement;
