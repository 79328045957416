import React, { useEffect, useState } from "react";
import { getContents } from "../apiServices/clinicianPanelHttpServices/supportHttpService/clinicianSupportHttpService";

import WebFooter from "./commonComponent/webFooter";
import WebHeader from "./commonComponent/webHeader";

function PrivacyPractice() {
  return (
    <>
      <div className="web-body">
        <WebHeader />

        <div className="row justify-content-center ">
          <div className="col-10 mb-4 mt-5">
            <div className="row justify-content-center ">
              <div className="col-auto">
                <h2 style={{ fontSize: "30px" }}>
                  Notice of Privacy Practices (NPP)
                </h2>
              </div>
            </div>
            <div className="row mx-1">
              <div className="col-12 all_page_text py-4">
                <p>
                  THIS NOTICE DESCRIBES HOW HEALTH INFORMATION MAY BE USED AND
                  DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION.
                  PLEASE REVIEW IT CAREFULLY.
                </p>
                <ol>
                  <li>
                    <p>
                      Your medical records are used to provide treatment, to
                      bill and receive payments, to conduct healthcare
                      operations and to create a research model for a published
                      framework.{" "}
                    </p>
                    <ul>
                      <li>
                        <p>
                          Examples of these activities include but are not
                          limited to: review of treatment records to ensure
                          appropriate care, electronic or mail delivery of
                          billing for treatment to you or other authorized
                          payers, appointment reminder telephone calls, emails,
                          and/or text messages, and records review to ensure
                          completeness and quality of care. Progress notes are
                          part of your medical record.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      Use or disclosure of your personal information is limited
                      as outlined in Section 1, with the following exceptions:
                    </p>
                  </li>
                  <ul>
                    <li>
                      <p>
                        Any information we disclose as described in Section 2
                        will be limited to the minimum necessary. Any reports we
                        make will be disclosed to you. We are available to
                        answer any questions you may have about these
                        exceptions. We will let you know promptly iFederal and
                        State laws require me to report abuse or neglect of (1)
                        a child, (2) an adult 65 years or older, or (3) an
                        individual with a disability, to an appropriate
                        protective agency.
                      </p>
                    </li>
                    <li>
                      <p>
                        We are required to report any credible threats to your
                        safety or the safety of others to an appropriate law
                        enforcement agency.
                      </p>
                    </li>
                    <li>
                      <p>
                        Service Providers - We may employ third-party companies
                        and individuals to facilitate our Platform, to perform
                        certain tasks which are related to the Platform, or to
                        provide audit, legal, operational, or other services for
                        us. These tasks include, but are not limited to,
                        customer service, technical maintenance, monitoring,
                        email management and communication, database management,
                        billing and payment processing, reporting and analytics.
                        We will share with them only the minimum necessary
                        information to perform their task for us and only after
                        entering into appropriate confidentiality agreements f a
                        breach occurs that may have compromised the privacy or
                        security of your information.
                      </p>
                    </li>
                  </ul>
                  <li>
                    <p>
                      You may request a copy or summary of your records be
                      disclosed to yourself or someone else. Your request must
                      be made in writing to your assigned clinician, clearly
                      identify the person or entity you want the information
                      released to, specify the information you want disclosed,
                      and include the expiration date of the authorization.{" "}
                    </p>
                    <ul>
                      <li>
                        <p>You may revoke any authorization at any time.</p>
                      </li>
                      <li>
                        <p>
                          We will respond to a disclosure request within 30
                          days.
                        </p>
                      </li>
                      <li>
                        <p>You may request corrections to your records.</p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      You may request that we restrict uses and disclosures
                      outlined in Section 1. However, we are not required to
                      agree to the restrictions. If an agreement is made to
                      restrict use or disclosure, we will be bound by such
                      restriction until revoked by you or when disclosure is
                      required by law or in an emergency.
                    </p>
                  </li>

                  <li>
                    <p>
                      As early users of this new teletherapy platform, your
                      intake questionnaire data and ongoing relationship
                      assessment data will be deidentified and used in research
                      cases, studies and potential publications.
                      <li>
                        <p>
                          Security of Information - While using any
                          Internet-based service carries inherent security risks
                          that cannot be 100% prevented, our systems,
                          infrastructure, encryption technology, operation and
                          processes are all designed, built, and maintained with
                          your security and privacy in mind. We apply industry
                          standards and best practices to prevent any
                          unauthorized access, use, and disclosure. We comply
                          with all applicable federal laws, state laws, and
                          regulations regarding data privacy..
                        </p>
                      </li>
                      <li>
                        <p>
                          If you wish to file a complaint about privacy-related
                          issues, you may contact the Secretary of the
                          Department of Health and Human Services, 200
                          Independence Avenue S.W., Washington D.C., 20201. We
                          will not retaliate against you for filing a
                          complaint..
                        </p>
                      </li>
                      <li>
                        <p>
                          This Notice, including any modifications or
                          amendments, will be available within the Passport
                          Journeys App. You may also request a paper copy at any
                          time..
                        </p>
                      </li>
                      <li>
                        <p>
                          If you have any questions or complaints regarding this
                          Notice or other privacy concerns, you may call our
                          office at 1800-957-6533 or email our Privacy Officer -
                          <a href="mailto:laceytezino@passportjourneysapp.com">
                            laceytezino@passportjourneysapp.com..
                          </a>
                        </p>
                      </li>
                    </p>
                  </li>
                </ol>
                <p>Last Updated and Effective: October 10, 2022</p>
                <div className="row justify-content-center ">
                  <div className="col-auto">
                    <h2 style={{ fontSize: "30px" }}>Practice Policies</h2>
                  </div>
                </div>

                <strong>Fee</strong>
                <ul>
                  <li>
                    <p>
                      Month 1 Intake Appointments - $280 per Mother-Daughter
                      Pair{" "}
                    </p>
                    <ul>
                      <li>
                        <p>Initial intake consultation (30 minutes)</p>
                      </li>
                      <li>
                        <p>Intake Individual consultation (60 minutes)</p>
                      </li>
                      <li>
                        <p>Treatment planning consultation (30 minutes) </p>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <p>
                      Monthly app subscription - $220 per Mother-Daughter Pair
                    </p>
                    <ul>
                      <li>
                        <p>
                          Paired therapy – 2 live sessions (45 minutes each){" "}
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>

                <strong>Attendance</strong>
                <ul>
                  <li>
                    <p>
                      No-show, non-cancel: if there is a no-show/non-cancel you
                      will lose that session for the month. 
                    </p>
                  </li>
                  <li>
                    <p>
                      Cancellation policy: at least 24 business hours’ notice is
                      required for rescheduling appointments.
                    </p>
                  </li>
                  <li>
                    <p>
                      If you are late to an appointment, the session will still
                      end at the scheduled session time.
                    </p>
                  </li>
                  <li>
                    <p>
                      Both participants are required to be present once the
                      therapy process has started. 
                    </p>
                  </li>
                </ul>
                <strong>Insurance </strong>
                <ul>
                  <li>
                    <p>Currently, we are not accepting insurance. </p>
                  </li>
                  <li>
                    <p>
                      All intake appointments and monthly subscriptions through
                      the app will be self-pay, without the ability to provide a
                      super bill for reimbursement.{" "}
                    </p>
                    <ul>
                      <li>
                        <p>
                          As our platform expands, we will be striving for more
                          financial assistance and insurance capabilities.
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>Last Updated and Effective: October 10, 2022</p>
              </div>
            </div>
          </div>
        </div>
        <WebFooter />
      </div>
    </>
  );
}

export default PrivacyPractice;
