import React, { useEffect, useState } from "react";
import { getSubscriptionList } from "../../apiServices/subscriptionHttpService/adminSubscriptionHttpService";
import AdminHeader from "../commonComponent/adminHeader";
import AdminSidebar from "../commonComponent/adminSidebar";
import EditSubscription from "./editSubscription";
import EditSubscriptionMonthly from "./editSubscriptionMonthly";

function Subscription() {
  const [subscription, setSubscription] = useState("");

  useEffect(() => {
    getSubscriptionInfo();
  }, []);

  const getSubscriptionInfo = async () => {
    const { data } = await getSubscriptionList();
    if (!data.error) {
      setSubscription(data.results.subscriptions);
    }
  };

  return (
    <>
      {" "}
      <div className="admin_main">
        <AdminSidebar />
        <div className="admin_main_inner">
          <AdminHeader />
          <div className="admin_panel_data height_adjust">
            <div className="row subscription-management justify-content-center">
              <div className="col-12">
                <div className="row mx-0">
                  <div className="col-12 design_outter_comman shadow mb-4">
                    <div className="row comman_header justify-content-between">
                      <div className="col-auto">
                        <h2>Subscription Plan Management </h2>
                      </div>
                    </div>

                    <div class="row p-4">
                      <div class="col-12 p-0 help-support-table border border-2 border-bottom-0 rounded overflow-hidden border-light">
                        <nav>
                          <div
                            class="nav nav-tabs justify-content-center border-0"
                            id="nav-tab"
                            role="tablist"
                          >
                            <button
                              class="nav-link nav-link-width active"
                              id="nav-home-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-home"
                              type="button"
                              role="tab"
                              aria-controls="nav-home"
                              aria-selected="true"
                            >
                              Intake
                              <div className="edit_plan">
                                <a
                                  data-bs-toggle="modal"
                                  data-bs-target="#staticBackdrop"
                                  className="edit_form"
                                >
                                  <i className="fas fa-edit"></i>
                                </a>
                              </div>
                            </button>

                            <button
                              class="nav-link nav-link-width"
                              id="nav-profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-profile"
                              type="button"
                              role="tab"
                              aria-controls="nav-profile"
                              aria-selected="false"
                            >
                              Monthly
                              <div className="edit_plan">
                                <a
                                  data-bs-toggle="modal"
                                  data-bs-target="#staticBackdrop2"
                                  className="edit_form"
                                >
                                  <i className="fas fa-edit"></i>
                                </a>
                              </div>
                            </button>
                          </div>
                        </nav>
                        <div class="tab-content" id="nav-tabContent">
                          <div
                            class="tab-pane fade show active"
                            id="nav-home"
                            role="tabpanel"
                            aria-labelledby="nav-home-tab"
                          >
                            <form
                              className="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                              action=""
                            >
                              <div className="form-group col-4">
                                <label for="">Plan Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={subscription[1]?.plan_name}
                                  name="name"
                                  id="name"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label for="">Plan Price</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={subscription[1]?.plan_price}
                                  name="name"
                                  id="name"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label for="">Plan Duration (Month)</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={subscription[1]?.plan_duration}
                                  name="name"
                                  id="name"
                                />
                              </div>
                              <div className="form-group mb-0 col-12">
                                <label for="">Features</label>
                                <ul className="list-circle">
                                  {subscription[1]?.plan_features?.map(
                                    (item, index) => (
                                      <li>
                                        <p className="text-capitalize">
                                          {item}
                                        </p>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </form>
                          </div>

                          <div
                            class="tab-pane fade"
                            id="nav-profile"
                            role="tabpanel"
                            aria-labelledby="nav-profile-tab"
                          >
                            <form
                              className="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                              action=""
                            >
                              <div className="form-group col-4">
                                <label for="">Plan Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={subscription[0]?.plan_name}
                                  name="name"
                                  id="name"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label for="">Plan Price</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={subscription[0]?.plan_price}
                                  name="name"
                                  id="name"
                                />
                              </div>
                              <div className="form-group col-4">
                                <label for="">Plan Duration (Month)</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={subscription[0]?.plan_duration}
                                  name="name"
                                  id="name"
                                />
                              </div>
                              <div className="form-group mb-0 col-12">
                                <label for="">Features</label>
                                <ul className="list-circle">
                                  {subscription[0]?.plan_features?.map(
                                    (item, index) => (
                                      <li>
                                        <p className="text-capitalize">
                                          {item}
                                        </p>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade comman_modal"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <EditSubscription listing={getSubscriptionInfo} />
      </div>
      <div
        className="modal fade comman_modal"
        id="staticBackdrop2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <EditSubscriptionMonthly listing={getSubscriptionInfo} />
      </div>
    </>
  );
}

export default Subscription;
