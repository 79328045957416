import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WebHeader from "./commonComponent/webHeader";
import OwlCarousel from "react-owl-carousel";
import WebFooter from "./commonComponent/webFooter";
import { getSubscriptionList } from "../apiServices/subscriptionHttpService/adminSubscriptionHttpService";
import { useForm } from "react-hook-form";
import {
  subscribe,
  subscribeUs,
} from "../apiServices/adminHttpService/adminLoginHttpService";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function Index() {
  const [subscription, setSubscription] = useState("");

  useEffect(() => {
    getSubscriptionInfo();
  }, []);

  const checkout = async () => {
    const response = await subscribe();
    if (!response.data.error) {
      const link = document.createElement("a");
      link.href = response.data.results.url;
      link.target = "_blank";
      link.click();
    }
  };

  const state = {
    responsive: {
      0: {
        items: 1,
        dots: true,
        autoplay: true,
        margin: 0,
        autoHeight: true,
      },
      768: {
        items: 3,
        dots: true,
        autoplay: true,
      },
      1024: {
        items: 4,
        dots: true,
        autoplay: true,
      },
      1200: {
        items: 5,
      },
    },
  };

  const getSubscriptionInfo = async () => {
    const { data } = await getSubscriptionList();
    if (!data.error) {
      setSubscription(data.results.subscriptions);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);

    const response = await subscribeUs(data);
    if (!response.data.error) {
      document.getElementById("email").value = "";
      document.getElementById("first_name").value = "";
      document.getElementById("last_name").value = "";
      reset();
    }
  };

  return (
    <>
      {" "}
      <div className="web-body">
        <WebHeader />
        <section class="hero_section">
          <div class="container position-relative">
            <div class="row mx-0">
              <div class="col-lg-6 col-md-12 order-lg-0 order-md-1 order-1">
                <div class="hero_content">
                  <h1>Online Therapy for Mother-Daughter Relationships</h1>

                  <a
                    class="Buttn_comman Buttn-bg1"
                    href="https://apps.apple.com/us/app/passport-journeys/id1502438397"
                    target="_blank"
                  >
                    Download <i class="fa fa-apple mx-1" aria-hidden="true"></i>
                    App
                  </a>
                  <a
                    class="Buttn_comman Buttn-bg1 mt-3 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-3 ms-2"
                    href="https://play.google.com/store/apps/details?id=com.passportjourney&pli=1"
                    target="_blank"
                  >
                    Download{" "}
                    <i class="fa fa-android mx-1" aria-hidden="true"></i>
                    App
                  </a>
                </div>
              </div>
              <div class="col-12 d-lg-none d-md-block d-block text-center mb-md-5 mb-4">
                <img class="ipad_img" src="assets/img/banner_img.png" alt="" />
              </div>
            </div>
            <img class="banner_img" src="assets/img/banner_img.png" alt="" />
          </div>
        </section>
        <section class="mission_statement">
          <div class="container">
            <div class="row mx-md-0 align-items-center">
              <div class="col-md-6 mb-md-0 mb-4">
                <div class="mission_img">
                  {/* <img src="assets/img/mission_img.png" alt="" /> */}
                  <video controls autoplay="true" muted>
                    <source
                      src="https://passport-journey.s3.amazonaws.com/_Passport+Journey+Commercial+Final.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div class="col-md-6 ps-md-4">
                <div class="mission_content ps-xl-5 ps-lg-4 ps-md-0">
                  <p>
                    Our expert guidance unleashes the potential of
                    mother-daughter relationships by cultivating profound
                    intimacy, nurturing unshakable trust, and orchestrating
                    intentional, quality time together.
                  </p>

                  {/* <span>This is why we created Passport Journeys.</span> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="pj-appdownload">
          <div class="container">
            <div class="row mx-md-0 justify-content-center">
              <div class="col-lg-8 col-md-10 mb-5">
                <div class="pj-appdownload-heading text-center">
                  <h2>Passport Journeys</h2>
                  <p>
                    Our App Connects Mother-Daughter Pairs with Licensed
                    Therapists for Monthly Video Sessions, Interactive
                    Assignments, and Meaningful Bonding Activities.
                  </p>
                  {/* <div className="soon-banner">
                    <img src="assets/img/soon.png" alt="" />
                  </div> */}
                  <div class="download_btn">
                    <a
                      class="Buttn_comman Buttn-bg1 mx-md-2 mb-2"
                      href="https://apps.apple.com/us/app/passport-journeys/id1502438397"
                      target="_blank"
                    >
                      Download iOS App
                    </a>
                    <a
                      class="Buttn_comman Buttn-bg3 mx-md-2 mb-2"
                      href="https://play.google.com/store/apps/details?id=com.passportjourney&pli=1"
                      target="_blank"
                    >
                      Download Android App
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-lg-5  mb-0 pb-5">
                <OwlCarousel
                  className="appdownload_slider owl-carousel"
                  autoplay={false}
                  autoplayHoverPause={false}
                  autoplayTimeout={5000}
                  dots={false}
                  autoHeight={true}
                  loop={false}
                  nav={false}
                  fade={false}
                  margin={23}
                  items={5}
                  responsive={state.responsive} // add this line
                >
                  <div class="item">
                    <img src="assets/img/1.png" alt="" />
                  </div>
                  <div class="item">
                    <img src="assets/img/2.png" alt="" />
                  </div>

                  <div class="item">
                    <img src="assets/img/3.png" alt="" />
                  </div>
                  <div class="item adjust_height">
                    <img src="assets/img/4.png" alt="" />
                  </div>

                  <div class="item">
                    <img src="assets/img/5.png" alt="" />
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>
        <section className="meeting_section">
          <div class="container">
            <div class="row mx-md-0">
              <div class="col-12  mother_daughter">
                <div
                  class="row monthly_teletherapy"
                  style={{ marginBottom: "80px" }}
                >
                  <div class="col-5 width_adjust d-flex align-items-stretch">
                    <div class="teletherapy_content">
                      <h2>Step 1: Complete the Intake Process</h2>
                      <p>
                        The first month serves as a baseline assessment with
                        your therapist to decide if the app is a good fit for
                        you both. All users are required to complete these 4
                        sessions in month 1.
                      </p>
                      <div class="subscribe_cost">
                        <h3>$280</h3>
                      </div>
                      <ul class="list-unstyled mb-0 ul_design">
                        {subscription?.plan_features?.map((item, index) => (
                          <li>
                            <p>{item}</p>
                          </li>
                        ))}
                      </ul>
                      <a
                        class="Buttn_comman Buttn-bg2"
                        href="https://passportjourney.page.link/tobR"
                        target="_blank"
                      >
                        Subscribe Now
                      </a>
                      <img
                        className="main"
                        src="assets/img/intake.jpeg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col px-xl-5 px-lg-4 px-md-4 border-start d-flex align-items-stretch">
                    <div class="row">
                      <div class="col-12">
                        <div class="teletherapy_boxes">
                          <span class="img_box">
                            <img src="assets/img/Icon (25).png" alt="" />
                          </span>
                          <h3>Introductory Meeting+ Co-Intake</h3>
                          <p>
                            Joint introduction meeting between mother, daughter
                            and newly matched clinician.
                          </p>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="teletherapy_boxes">
                          <span class="img_box">
                            <img src="assets/img/Icon (24).png" alt="" />
                          </span>
                          <h3>Individual Intake - Mother</h3>
                          <p>
                            1 on 1 session with clinician and mother to assess
                            their prespective for current state of the
                            relationshipand to learn more about mother as an
                            individual.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col px-xl-5 px-lg-4 px-md-4 border-start d-flex align-items-stretch">
                    <div class="row">
                      <div class="col-12">
                        <div class="teletherapy_boxes">
                          <span class="img_box">
                            <img src="assets/img/icon (26).png" alt="" />
                          </span>
                          <h3>Intake Treatment Planning Session</h3>
                          <p>
                            Joint summary meeting to discuss whether the
                            Passport Journeys App is a good fit for the
                            mother-daughter pair, confirm commitment to the
                            program and next steps.
                          </p>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="teletherapy_boxes">
                          <span class="img_box">
                            <img src="assets/img/icon (27).png" alt="" />
                          </span>
                          <h3>Individual Intake - Daughter</h3>
                          <p>
                            1 on 1 session with clinician and daughter to assess
                            their perspective for current state of the
                            relationship and to learn more about daughter as an
                            individual.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mother_daughter ">
                <div class="row monthly_teletherapy mt-20">
                  <div class="col-5 width_adjust d-flex align-items-stretch">
                    <div class="teletherapy_content">
                      <h2>Step 2: Begin your monthly subscription.</h2>
                      <p>
                        We’ve designed your therapy to go beyond speaking with a
                        therapist. Each month includes personalized assignments
                        tailored to your relationship.
                      </p>
                      <div class="subscribe_cost">
                        <h3>$220/month</h3>
                      </div>
                      <ul class="list-unstyled mb-0 ul_design">
                        {subscription?.plan_features?.map((item, index) => (
                          <li>
                            <p>{item}</p>
                          </li>
                        ))}
                      </ul>
                      <a
                        class="Buttn_comman Buttn-bg2"
                        href="https://passportjourney.page.link/tobR"
                        target="_blank"
                      >
                        Subscribe Now
                      </a>
                      <img
                        className="main"
                        src="assets/img/monthly.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col px-xl-5 px-lg-4 px-md-4 border-start d-flex align-items-stretch">
                    <div class="row">
                      <div class="col-12">
                        <div class="teletherapy_boxes">
                          <span class="img_box">
                            <img
                              src="assets/img/live-video-sessions.png"
                              alt=""
                            />
                          </span>
                          <h3>Live Video Sessions</h3>
                          <p>
                            A bi-weekly video therapy session with your assigned
                            licensed clinician
                            <br />
                            <span>2 total sessions per month</span>
                          </p>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="teletherapy_boxes">
                          <span class="img_box">
                            <img
                              src="assets/img/journal-promotion2.png"
                              alt=""
                            />
                          </span>
                          <h3>Journal Prompts</h3>
                          <p>
                            Intimate journal prompts curated to pull out deep
                            thoughts to stimulate growth and healing through
                            writing
                            <br />
                            <span>1 assigned per month</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col px-xl-5 px-lg-4 px-md-4 border-start d-flex align-items-stretch">
                    <div class="row">
                      <div class="col-12">
                        <div class="teletherapy_boxes">
                          <span class="img_box">
                            <img
                              src="assets/img/prescribed-activities.png"
                              alt=""
                            />
                          </span>
                          <h3>Prescribed Activities</h3>
                          <p>
                            Thoughtful in-person or virtual activities assigned
                            in between video sessions to promote intentional
                            bonding
                            <br />
                            <span>1 activity assigned per month</span>
                          </p>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="teletherapy_boxes">
                          <span class="img_box">
                            <img src="assets/img/worksheets.png" alt="" />
                          </span>
                          <h3>Worksheets</h3>
                          <p>
                            Clinician-selected homework to sharpen a particular
                            skill based on your goals
                            <br />
                            <span>1 assigned per month</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section class="therepy_launch">
          <div class="container">
            <div class="row mx-md-0">
              <div class="col-12 therepy_launch_heading">
                <h2>How do you and your mother/daughter get started?</h2>
              </div> 
             <div class="col-12">
                <div class="therepy_launch_outter">
                  <div class="therepy_launch_box">
                    <div class="therepy_img">
                      <img src="assets/img/hand.png" alt="" />
                      <span>01</span>
                    </div>
                    <div class="therepy_content">
                      <p>Pay for month 1 of our intake process</p>
                      <Link
                        class="Buttn_comman Buttn-bg1"
                        onClick={checkout}
                        to=""
                      >
                        Pay now
                      </Link>
                    </div>
                  </div>
                  <div class="therepy_launch_box">
                    <div class="therepy_img">
                      <img src="assets/img/img_2.png" alt="" />
                      <span>02</span>
                    </div>
                    <div class="therepy_content">
                      <p>Complete our intake questionnaire</p>
                      <a
                        href="https://corexmslmtdwdd7f6wtd.qualtrics.com/jfe/form/SV_1AGFpRXrxyvBdMq"
                        target="_blank"
                        class="Buttn_comman Buttn-bg1"
                      >
                        Click here
                      </a>
                    </div>
                  </div>
                  <div class="therepy_launch_box">
                    <div class="therepy_img">
                      <img src="assets/img/img_3.png" alt="" />
                      <span>03</span>
                    </div>
                    <div class="therepy_content">
                      <p>
                        Your intake questionnaires will be reviewed and you will
                        be matched to a licensed clinician
                      </p>
                    </div>
                  </div>
                  <div class="therepy_launch_box">
                    <div class="therepy_img">
                      <img src="assets/img/img_4.png" alt="" />
                      <span>04</span>
                    </div>
                    <div class="therepy_content">
                      <p>
                        Your clinician will schedule 4 total appointments for
                        month 1
                      </p>
                      <Link
                        class="Buttn_comman Buttn-bg1"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                        to=""
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                  <div class="therepy_launch_box">
                    <div class="therepy_img">
                      <img src="assets/img/img_5.png" alt="" />
                      <span>05</span>
                    </div>
                    <div class="therepy_content">
                      <p>
                        Mother-Daughter pairs who are a good fit for our app
                        model will be instructed to begin their monthly
                        subscription in the app.
                      </p>
                    </div>
                  </div>
                </div>
              </div>  
              <div class="col-12">
                <div class="therepy_bottom text-center py-0"></div>
              </div>
            </div>
          </div>
        </section> */}
        <section class="subscribeer_sec">
          <div class="container">
            <div class="row mx-md-0">
              <div class="col-12 subscribeer_heading text-center">
                <h2>In The Know</h2>
                <p>
                  Sign up with your email address to receive news and updates.
                </p>
              </div>
              <div class="col-12">
                <form
                  class="subscribe_form row"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div class="form-group col-3 width_comman">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="First Name"
                      id="first_name"
                      name="first_name"
                      {...register("first_name", {
                        required: true,
                      })}
                    />
                    {errors?.first_name && (
                      <p className="form-error mt-2">This field is required</p>
                    )}
                  </div>
                  <div class="form-group col-3 width_comman">
                    <input
                      type="text"
                      class="form-control"
                      id="last_name"
                      name="last_name"
                      placeholder="Last Name"
                      {...register("last_name", {
                        required: true,
                      })}
                    />
                    {errors?.last_name && (
                      <p className="form-error mt-2">This field is required</p>
                    )}
                  </div>
                  <div class="form-group col">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Email Address"
                      name="email"
                      id="email"
                      {...register("email", {
                        required: "This field is required",
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Invalid email address",
                        },
                      })}
                    />

                    {errors?.email && (
                      <p className="form-error mt-2">{errors.email.message}</p>
                    )}
                  </div>
                  <div class="form-group col-2 width_comman">
                    <button
                      class="Buttn_comman Buttn-bg2 border-0"
                      type="submit"
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <WebFooter />
      </div>
      <div
        class="modal fade comman_modal"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-0">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                How do you and your mother/daughter get started?
              </h5>
            </div>
            <div class="modal-body py-4 px-4">
              <div class="therepy_content">
                <span className="read-more3">
                  Your clinician will schedule 4 total appointments for month 1
                </span>
                <ol>
                  <li>
                    <p className="read-more mt-0">
                      Introductory Meeting and Co-Intake- 30 minutes
                    </p>{" "}
                    <span className="read-more3">
                      Joint introduction meeting between mother, daughter and
                      newly matched clinician.
                    </span>
                  </li>
                  <li>
                    <p className="read-more">
                      Individual Intake – Mother- 60 minutes
                    </p>{" "}
                    <span className="read-more3">
                      One-on-One session with clinician and mother to assess
                      their perspective for current state of the relationship
                      and to learn more about mother as an individual.
                    </span>
                  </li>
                  <li>
                    <p className="read-more">
                      Individual Intake – Daughter- 60 minutes
                    </p>{" "}
                    <span className="read-more3">
                      One-on-One session with clinician and daughter to assess
                      their perspective for current state of the relationship
                      and to learn more about mother as an individual.
                    </span>
                  </li>
                  <li>
                    <p className="read-more">
                      Intake Treatment Planning Session- 30 minutes
                    </p>{" "}
                    <span className="read-more3">
                      Joint summary meeting to confirm if the Passport Journeys
                      app will be a good fit for the mother-daughter pair and
                      next steps.{" "}
                    </span>
                  </li>
                </ol>
                <p className="read-more2">
                  *All sessions will happen through Zoom video appointments, no
                  in person meetings. 
                </p>
              </div>
              <div class="form-group mb-0 mt-3 col-12 text-center">
                <button
                  class="comman_btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
