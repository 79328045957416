

export const timeArray = [
  [
    { time1: "08:00 AM", time2: "09:00 AM", selected: false, day: "sunday" },
    { time1: "09:00 AM", time2: "10:00 AM", selected: false, day: "sunday" },
    { time1: "10:00 AM", time2: "11:00 AM", selected: false, day: "sunday" },
    { time1: "11:00 AM", time2: "12:00 PM", selected: false, day: "sunday" },
    { time1: "12:00 PM", time2: "01:00 PM", selected: false, day: "sunday" },
    { time1: "01:00 PM", time2: "02:00 PM", selected: false, day: "sunday" },
    { time1: "02:00 PM", time2: "03:00 PM", selected: false, day: "sunday" },
    { time1: "03:00 PM", time2: "04:00 PM", selected: false, day: "sunday" },
    { time1: "04:00 PM", time2: "05:00 PM", selected: false, day: "sunday" },
    { time1: "05:00 PM", time2: "06:00 PM", selected: false, day: "sunday" },
    { time1: "06:00 PM", time2: "07:00 PM", selected: false, day: "sunday" },
    { time1: "07:00 PM", time2: "08:00 PM", selected: false, day: "sunday" },
    { time1: "08:00 PM", time2: "09:00 PM", selected: false, day: "sunday" },
  ],
  [
    { time1: "08:00 AM", time2: "09:00 AM", selected: false, day: "monday" },
    { time1: "09:00 AM", time2: "10:00 AM", selected: false, day: "monday" },
    { time1: "10:00 AM", time2: "11:00 AM", selected: false, day: "monday" },
    { time1: "11:00 AM", time2: "12:00 PM", selected: false, day: "monday" },
    { time1: "12:00 PM", time2: "01:00 PM", selected: false, day: "monday" },
    { time1: "01:00 PM", time2: "02:00 PM", selected: false, day: "monday" },
    { time1: "02:00 PM", time2: "03:00 PM", selected: false, day: "monday" },
    { time1: "03:00 PM", time2: "04:00 PM", selected: false, day: "monday" },
    { time1: "04:00 PM", time2: "05:00 PM", selected: false, day: "monday" },
    { time1: "05:00 PM", time2: "06:00 PM", selected: false, day: "monday" },
    { time1: "06:00 PM", time2: "07:00 PM", selected: false, day: "monday" },
    { time1: "07:00 PM", time2: "08:00 PM", selected: false, day: "monday" },
    { time1: "08:00 PM", time2: "09:00 PM", selected: false, day: "monday" },
  ],
  [
    { time1: "08:00 AM", time2: "09:00 AM", selected: false, day: "tuesday" },
    { time1: "09:00 AM", time2: "10:00 AM", selected: false, day: "tuesday" },
    { time1: "10:00 AM", time2: "11:00 AM", selected: false, day: "tuesday" },
    { time1: "11:00 AM", time2: "12:00 PM", selected: false, day: "tuesday" },
    { time1: "12:00 PM", time2: "01:00 PM", selected: false, day: "tuesday" },
    { time1: "01:00 PM", time2: "02:00 PM", selected: false, day: "tuesday" },
    { time1: "02:00 PM", time2: "03:00 PM", selected: false, day: "tuesday" },
    { time1: "03:00 PM", time2: "04:00 PM", selected: false, day: "tuesday" },
    { time1: "04:00 PM", time2: "05:00 PM", selected: false, day: "tuesday" },
    { time1: "05:00 PM", time2: "06:00 PM", selected: false, day: "tuesday" },
    { time1: "06:00 PM", time2: "07:00 PM", selected: false, day: "tuesday" },
    { time1: "07:00 PM", time2: "08:00 PM", selected: false, day: "tuesday" },
    { time1: "08:00 PM", time2: "09:00 PM", selected: false, day: "tuesday" },
  ],
  [
    { time1: "08:00 AM", time2: "09:00 AM", selected: false, day: "wednesday" },
    { time1: "09:00 AM", time2: "10:00 AM", selected: false, day: "wednesday" },
    { time1: "10:00 AM", time2: "11:00 AM", selected: false, day: "wednesday" },
    { time1: "11:00 AM", time2: "12:00 PM", selected: false, day: "wednesday" },
    { time1: "12:00 PM", time2: "01:00 PM", selected: false, day: "wednesday" },
    { time1: "01:00 PM", time2: "02:00 PM", selected: false, day: "wednesday" },
    { time1: "02:00 PM", time2: "03:00 PM", selected: false, day: "wednesday" },
    { time1: "03:00 PM", time2: "04:00 PM", selected: false, day: "wednesday" },
    { time1: "04:00 PM", time2: "05:00 PM", selected: false, day: "wednesday" },
    { time1: "05:00 PM", time2: "06:00 PM", selected: false, day: "wednesday" },
    { time1: "06:00 PM", time2: "07:00 PM", selected: false, day: "wednesday" },
    { time1: "07:00 PM", time2: "08:00 PM", selected: false, day: "wednesday" },
    { time1: "08:00 PM", time2: "09:00 PM", selected: false, day: "wednesday" },
  ],
  [
    { time1: "08:00 AM", time2: "09:00 AM", selected: false, day: "thursday" },
    { time1: "09:00 AM", time2: "10:00 AM", selected: false, day: "thursday" },
    { time1: "10:00 AM", time2: "11:00 AM", selected: false, day: "thursday" },
    { time1: "11:00 AM", time2: "12:00 PM", selected: false, day: "thursday" },
    { time1: "12:00 PM", time2: "01:00 PM", selected: false, day: "thursday" },
    { time1: "01:00 PM", time2: "02:00 PM", selected: false, day: "thursday" },
    { time1: "02:00 PM", time2: "03:00 PM", selected: false, day: "thursday" },
    { time1: "03:00 PM", time2: "04:00 PM", selected: false, day: "thursday" },
    { time1: "04:00 PM", time2: "05:00 PM", selected: false, day: "thursday" },
    { time1: "05:00 PM", time2: "06:00 PM", selected: false, day: "thursday" },
    { time1: "06:00 PM", time2: "07:00 PM", selected: false, day: "thursday" },
    { time1: "07:00 PM", time2: "08:00 PM", selected: false, day: "thursday" },
    { time1: "08:00 PM", time2: "09:00 PM", selected: false, day: "thursday" },
  ],
  [
    { time1: "08:00 AM", time2: "09:00 AM", selected: false, day: "friday" },
    { time1: "09:00 AM", time2: "10:00 AM", selected: false, day: "friday" },
    { time1: "10:00 AM", time2: "11:00 AM", selected: false, day: "friday" },
    { time1: "11:00 AM", time2: "12:00 PM", selected: false, day: "friday" },
    { time1: "12:00 PM", time2: "01:00 PM", selected: false, day: "friday" },
    { time1: "01:00 PM", time2: "02:00 PM", selected: false, day: "friday" },
    { time1: "02:00 PM", time2: "03:00 PM", selected: false, day: "friday" },
    { time1: "03:00 PM", time2: "04:00 PM", selected: false, day: "friday" },
    { time1: "04:00 PM", time2: "05:00 PM", selected: false, day: "friday" },
    { time1: "05:00 PM", time2: "06:00 PM", selected: false, day: "friday" },
    { time1: "06:00 PM", time2: "07:00 PM", selected: false, day: "friday" },
    { time1: "07:00 PM", time2: "08:00 PM", selected: false, day: "friday" },
    { time1: "08:00 PM", time2: "09:00 PM", selected: false, day: "friday" },
  ],
  [
    { time1: "08:00 AM", time2: "09:00 AM", selected: false, day: "saturday" },
    { time1: "09:00 AM", time2: "10:00 AM", selected: false, day: "saturday" },
    { time1: "10:00 AM", time2: "11:00 AM", selected: false, day: "saturday" },
    { time1: "11:00 AM", time2: "12:00 PM", selected: false, day: "saturday" },
    { time1: "12:00 PM", time2: "01:00 PM", selected: false, day: "saturday" },
    { time1: "01:00 PM", time2: "02:00 PM", selected: false, day: "saturday" },
    { time1: "02:00 PM", time2: "03:00 PM", selected: false, day: "saturday" },
    { time1: "03:00 PM", time2: "04:00 PM", selected: false, day: "saturday" },
    { time1: "04:00 PM", time2: "05:00 PM", selected: false, day: "saturday" },
    { time1: "05:00 PM", time2: "06:00 PM", selected: false, day: "saturday" },
    { time1: "06:00 PM", time2: "07:00 PM", selected: false, day: "saturday" },
    { time1: "07:00 PM", time2: "08:00 PM", selected: false, day: "saturday" },
    { time1: "08:00 PM", time2: "09:00 PM", selected: false, day: "saturday" },
  ],
];
