import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  deleteJoin,
  getJoinList,
  getSubscribeList,
} from "../../apiServices/adminHttpService/adminLoginHttpService";

function OurTeam() {
  const [delId, setDelId] = useState("");
  const [from, setFrom] = useState("");
  const [till, setTill] = useState("");
  const [sub, setSub] = useState({
    columns: [
      {
        label: "S.No.",
        field: "sn",
        sort: "asc",
        width: 150,
      },
      {
        label: " Date & Time",
        field: "date",
        sort: "asc",
        width: 100,
      },

      {
        label: "First Name",
        field: "first_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Last Name",
        field: "last_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Licensure",
        field: "licensure",
        sort: "asc",
        width: 150,
      },
      {
        label: "Resume",
        field: "resume",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });

  useEffect(() => {
    getQueryListing();
  }, []);

  const getQueryListing = async () => {
    const formData = {
      from: from,
      till: till,
    };
    const { data } = await getJoinList(formData);
    if (!data.error) {
      const newRows = [];
      console.log(data);
      data.results.query.map((list, index) => {
        const returnData = {};

        returnData.sn = index + 1;
        returnData.first_name = list.first_name;
        returnData.last_name = list.last_name;
        returnData.email = list.email;
        returnData.date = moment(list.createdAt).format("L");
        returnData.licensure = list.licensure;
        returnData.resume = (
          <div className="header-imgg">
            <a href={list.resume} target="_blank">
              <i
                className="fa fa-file-pdf-o"
                style={{ fontSize: "20px", color: "#000" }}
              ></i>
            </a>
          </div>
        );
        returnData.action = (
          <>
            <Link
              className="comman_btn table_viewbtn ms-2"
              to=""
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop2"
              onClick={() => setDelId(list._id)}
            >
              Delete
            </Link>
          </>
        );

        newRows.push(returnData);
      });

      setSub({ ...sub, rows: newRows });
    }
  };

  const deleteData = async () => {
    const { data } = await deleteJoin(delId);
    if (!data.error) {
      await getQueryListing();
      setDelId("");
    }
  };

  return (
    <>
      <div className="row mx-0">
        <div className="col-12">
          <form
            className="form-design py-4 px-3 row align-items-end justify-content-between"
            action=""
          >
            <div className="form-group mb-0 col-5">
              <label for="">From</label>
              <input
                type="datetime-local"
                className="form-control"
                onChange={(e) => setFrom(e.target.value)}
              />
            </div>
            <div className="form-group mb-0 col-5">
              <label for="">To</label>
              <input
                type="datetime-local"
                className="form-control"
                onChange={(e) => setTill(e.target.value)}
              />
            </div>
            <div className="form-group mb-0 col-auto">
              <Link
                className="comman_btn mt-4"
                onClick={() => getQueryListing()}
                to=""
              >
                Search
              </Link>
            </div>
          </form>
          <div className="row">
            <div className="col-12 comman_table_design px-0">
              <div className="table-responsive">
                <MDBDataTable
                  bordered
                  hover
                  data={sub}
                  noBottomColumns
                  sortable
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="staticBackdrop2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-0">
            <div class="modal-body text-end">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close"
              ></button>
              <div class="row">
                <div class="col-12 Update_modal_content text-center py-4">
                  <h2>Delete</h2>
                  <p>Are you sure, Want to delete this?</p>
                  <Link
                    class="comman_btn2 mx-2"
                    onClick={() => deleteData()}
                    data-bs-dismiss="modal"
                    to=""
                  >
                    Yes
                  </Link>
                  <Link
                    class="comman_btn mx-2"
                    data-bs-dismiss="modal"
                    to=""
                    onClick={() => setDelId("")}
                  >
                    NO
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurTeam;
