import React, { useEffect, useState } from "react";

import { assignActivity } from "../../apiServices/clinicianPanelHttpServices/loginHttpService/clinicianLoginHttpService";

import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

function Activity({ sessionId, update }) {
  const [selectedFile3, setSelectedFile3] = useState(null);

  const onFileSelection3 = (event) => {
    let file = event.target.files[0];
    setSelectedFile3(file);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const addActivity = async (data) => {
    console.log(data);
    if (!selectedFile3) {
      toast.error("Please upload image");
      return;
    }

    data.sessionId = sessionId;
    data.activityId = "";

    const formData = new FormData();
    for (const item in data) {
      formData.append(item, data[item]);
      console.log(data[item]);
    }
    console.log(data);

    if (selectedFile3) {
      formData.append("image", selectedFile3, selectedFile3.name);
    }

    const response = await assignActivity(formData);
    if (!response.data.error) {
      console.log(data);

      await update();

      let defaultValue = {};
      defaultValue.title = "";
      defaultValue.message = "";
      defaultValue.type = "";

      reset({ ...defaultValue });

      setSelectedFile3(null);
      document.getElementById("input-file").value = "";
    }
  };

  return (
    <>
      <form
        class="form-design py-4 px-3 help-support-form 
                  row align-items-end justify-content-between"
        onSubmit={handleSubmit(addActivity)}
        id="activity"
      >
        <div class="form-group col-6 mb-4 ">
          <label for="">Title</label>
          <input
            type="text"
            class="form-control"
            id="title"
            name="title"
            {...register("title", {
              required: true,
            })}
          />
          {errors?.title && (
            <p className="form-error mt-2">This field is required</p>
          )}
        </div>
        <div class="form-group col-6 mb-4 choose_file position-relative">
          <span>Upload Image</span>
          <label for="input-file">
            <i class="fal fa-camera me-1"></i>
            Choose File
          </label>
          <input
            class="form-control"
            type="file"
            accept=".png, .jpg, .jpeg"
            id="input-file"
            onChange={(e) => onFileSelection3(e)}
          />{" "}
        </div>
        <div class="form-group col-6 mb-0">
          <label for="">Message</label>
          <input
            type="text"
            class="form-control"
            id="message"
            name="message"
            {...register("message", {
              required: true,
            })}
          />
          {errors?.message && (
            <p className="form-error mt-2">This field is required</p>
          )}
        </div>
        <div class="form-group col-6 multiple_select_design mb-0">
          <label for="">Select Type</label>
          <select
            class="form-control"
            id="type"
            name="type"
            {...register("type", {
              required: true,
            })}
          >
            <option value="" selected>
              Select
            </option>
            <option value="In-person">In-person</option>
            <option value="Virtual">Virtual</option>
          </select>
          {errors?.type && (
            <p className="form-error mt-2">This field is required</p>
          )}
        </div>
        <div class="form-group mb-0 col-6 mt-4 ">
          <button class="comman_btn" type="submit" id="activity">
            Save
          </button>
        </div>
      </form>
    </>
  );
}

export default Activity;
