import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";

const ClinicianDocDummy = () => {
  const [type, setType] = useState(1);
  const [activeTab, setActiveTab] = useState(type === 1 ? "IM" : "VS");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <h1 className="text-center fs-5">
        The session has not been scheduled yet.
      </h1>
      <div class="row py-4 px-4">
        <div class="col-12 mb-4">
          <OwlCarousel
            className="slider_session owl-carousel"
            autoplay={false}
            autoplayHoverPause={true}
            autoplayTimeout={5000}
            dots={false}
            loop={false}
            autoWidth={false}
            nav={true}
            fade={true}
            items={2}
            stagePadding={20}
          >
            <Link
              class="slider_menu item active"
              onClick={() => {
                setType(1);
                handleTabClick("IM");
              }}
              to=""
            >
              Intake
            </Link>
            <Link
              class="slider_menu item active"
              onClick={() => {
                setType(2);
                handleTabClick("VS");
              }}
              to=""
            >
              Month
            </Link>
          </OwlCarousel>
        </div>
        <div
          id="video_list_1"
          className="video_list col-12 session_history_tabs px-0 border rounded overflow-hidden"
        >
          <nav>
            {type === 1 ? (
              <div
                className="nav two_navtabs nav-tabs justify-content-center border-0"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className={`nav-link ${activeTab === "IM" ? "active" : ""}`}
                  id="nav-IM-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-IM"
                  type="button"
                  role="tab"
                  aria-controls="nav-IM"
                  aria-selected={activeTab === "IM"}
                  onClick={() => handleTabClick("IM")}
                >
                  Introductory Meeting
                </button>
                <button
                  className={`nav-link ${activeTab === "IIM" ? "active" : ""}`}
                  id="nav-IIM-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-IIM"
                  type="button"
                  role="tab"
                  aria-controls="nav-IIM"
                  aria-selected={activeTab === "IIM"}
                  onClick={() => handleTabClick("IIM")}
                >
                  Individual Intake - Daughter
                </button>
                <button
                  className={`nav-link ${activeTab === "IID" ? "active" : ""}`}
                  id="nav-IID-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-IID"
                  type="button"
                  role="tab"
                  aria-controls="nav-IID"
                  aria-selected={activeTab === "IID"}
                  onClick={() => handleTabClick("IID")}
                >
                  Individual Intake - Mother
                </button>
                <button
                  className={`nav-link ${activeTab === "TP" ? "active" : ""}`}
                  id="nav-TP-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-TP"
                  type="button"
                  role="tab"
                  aria-controls="nav-TP"
                  aria-selected={activeTab === "TP"}
                  onClick={() => handleTabClick("TP")}
                >
                  Treatment Planning
                </button>
              </div>
            ) : (
              <div
                className="nav two_navtabs nav-tabs justify-content-center border-0"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className={`nav-link ${activeTab === "VS" ? "active" : ""}`}
                  id="nav-VS-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-VS"
                  type="button"
                  role="tab"
                  aria-controls="nav-VS"
                  aria-selected={activeTab === "VS"}
                  onClick={() => handleTabClick("VS")}
                >
                  Video Session
                </button>
              </div>
            )}
          </nav>

          <div className="tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "IM" ? "show active" : ""
              }`}
              id="nav-IM"
              role="tabpanel"
              aria-labelledby="nav-IM-tab"
            >
              <div class="col-12 design_outter_comman border rounded mb-4">
                <div class="row comman_header justify-content-between">
                  <div class="col">
                    <h2>Clinical Documentation</h2>
                  </div>
                </div>
                <form class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between">
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      What prompted you to start this journey?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="start_reason"
                      name="start_reason"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      What are your goals in starting Passport Journeys?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="goals"
                      name="goals"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      Have either of you been in counseling/therapy before
                      either separately or together?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="therapy_history"
                      name="therapy_history"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">Orientation and Questions</label>
                    <textarea
                      class="form-control textarea_"
                      id="focus_of_session"
                      name="focus_of_session"
                      disabled={true}
                    />
                  </div>

                  <div class="form-group col-6 clinical_documentation">
                    <label for="">Safety Concerns</label>
                    <textarea
                      class="form-control textarea_"
                      id="safety_concerns"
                      name="safety_concerns"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">Observations and Assessment</label>
                    <textarea
                      class="form-control textarea_"
                      id="observations_and_assessment"
                      name="observations_and_assessment"
                      disabled={true}
                    />
                  </div>

                  <div class="form-group col-6  clinical_documentation">
                    <label for="">Plan</label>
                    <textarea
                      class="form-control textarea_"
                      id="plan"
                      name="plan"
                      disabled={true}
                    />
                  </div>

                  <div class="form-group col-6 mb-0 clinical_documentation mt-4">
                    <label for="">Session Attendee Names</label>
                    <input
                      type="text"
                      class="form-control"
                      id="attendees"
                      disabled={true}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "IIM" ? "show active" : ""
              }`}
              id="nav-IIM"
              role="tabpanel"
              aria-labelledby="nav-IIM-tab"
            >
              <div class="col-12 design_outter_comman border rounded mb-4">
                <div class="row comman_header justify-content-between">
                  <div class="col">
                    <h2>Clinical Documentation</h2>
                  </div>
                </div>
                <form class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between">
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      How would you describe your mother-daughter relationship?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="mother_daughter_relationship"
                      name="mother_daughter_relationship"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      What are your goals for joining Passport Journeys?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="goals"
                      name="goals"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      What things work well within the relationship?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="well_within_relationship"
                      name="well_within_relationship"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      What would you like to see improve in the relationship?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="improve"
                      name="improve"
                      disabled={true}
                    />
                  </div>

                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      What do you hope for in your mother-daughter relationship?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="hope"
                      name="hope"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      What do you identify as the biggest difficulty in the
                      relationship?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="difficulty_in_relationship"
                      name="difficulty_in_relationship"
                      disabled={true}
                    />
                  </div>

                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      What is the most important change you would like to
                      achieve in going on this journey?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="change"
                      name="change"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      Do you currently see a therapist? If no, what support do
                      you have in place?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="therapy_history"
                      name="therapy_history"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      What was helpful or not about your previous experience?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="previous_experience"
                      name="previous_experience"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      Is there other information you think is important for me
                      to know before moving forward?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="other_info"
                      name="other_info"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      Review of red flags: (alcohol and/or drug use, active
                      abuse in relationship, history of abuse, recent or
                      unprocessed trauma)
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="red_flags_review"
                      name="red_flags_review"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      Mental health screen: (past psychiatric history,
                      suicidality, violence, pertinent medical history){" "}
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="mental_health_screen"
                      name="mental_health_screen"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">Social history</label>
                    <textarea
                      class="form-control textarea_"
                      id="social_history"
                      name="social_history"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">Other pertinent information</label>
                    <textarea
                      class="form-control textarea_"
                      id="other_pertinent_information"
                      name="other_pertinent_information"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">Observations: (mental status exam)</label>
                    <textarea
                      class="form-control textarea_"
                      id="observations"
                      name="observations"
                      disabled={true}
                    />
                  </div>

                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      Assessment: (suitability for this type of therapy)
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="assessment"
                      name="assessment"
                      disabled={true}
                    />
                  </div>

                  <div class="form-group col-6  clinical_documentation">
                    <label for="">Plan</label>
                    <textarea
                      class="form-control textarea_"
                      id="plan"
                      name="plan"
                      disabled={true}
                    />
                  </div>

                  <div class="form-group col-6 mb-0 clinical_documentation mt-4">
                    <label for="">Session Attendee Names</label>
                    <input
                      type="text"
                      class="form-control"
                      id="attendees"
                      name="attendees"
                      disabled={true}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "IID" ? "show active" : ""
              }`}
              id="nav-IID"
              role="tabpanel"
              aria-labelledby="nav-IID-tab"
            >
              <div class="col-12 design_outter_comman border rounded mb-4">
                <div class="row comman_header justify-content-between">
                  <div class="col">
                    <h2>Clinical Documentation</h2>
                  </div>
                </div>
                <form class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between">
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      How would you describe your mother-daughter relationship?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="mother_daughter_relationship"
                      name="mother_daughter_relationship"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      What are your goals for joining Passport Journeys?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="goals"
                      name="goals"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      What things work well within the relationship?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="well_within_relationship"
                      name="well_within_relationship"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      What would you like to see improve in the relationship?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="improve"
                      name="improve"
                      disabled={true}
                    />
                  </div>

                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      What do you hope for in your mother-daughter relationship?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="hope"
                      name="hope"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      What do you identify as the biggest difficulty in the
                      relationship?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="difficulty_in_relationship"
                      name="difficulty_in_relationship"
                      disabled={true}
                    />
                  </div>

                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      What is the most important change you would like to
                      achieve in going on this journey?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="change"
                      name="change"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      Do you currently see a therapist? If no, what support do
                      you have in place?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="therapy_history"
                      name="therapy_history"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      What was helpful or not about your previous experience?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="previous_experience"
                      name="previous_experience"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      Is there other information you think is important for me
                      to know before moving forward?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="other_info"
                      name="other_info"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      Review of red flags: (alcohol and/or drug use, active
                      abuse in relationship, history of abuse, recent or
                      unprocessed trauma)
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="red_flags_review"
                      name="red_flags_review"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      Mental health screen: (past psychiatric history,
                      suicidality, violence, pertinent medical history){" "}
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="mental_health_screen"
                      name="mental_health_screen"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">Social history</label>
                    <textarea
                      class="form-control textarea_"
                      id="social_history"
                      name="social_history"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">Other pertinent information</label>
                    <textarea
                      class="form-control textarea_"
                      id="other_pertinent_information"
                      name="other_pertinent_information"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">Observations: (mental status exam)</label>
                    <textarea
                      class="form-control textarea_"
                      id="observations"
                      name="observations"
                      disabled={true}
                    />
                  </div>

                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      Assessment: (suitability for this type of therapy)
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="assessment"
                      name="assessment"
                      disabled={true}
                    />
                  </div>

                  <div class="form-group col-6  clinical_documentation">
                    <label for="">Plan</label>
                    <textarea
                      class="form-control textarea_"
                      id="plan"
                      name="plan"
                      disabled={true}
                    />
                  </div>

                  <div class="form-group col-6 mb-0 clinical_documentation mt-4">
                    <label for="">Session Attendee Names</label>
                    <input
                      type="text"
                      class="form-control"
                      id="attendees"
                      name="attendees"
                      disabled={true}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "TP" ? "show active" : ""
              }`}
              id="nav-TP"
              role="tabpanel"
              aria-labelledby="nav-TP-tab"
            >
              <div class="col-12 design_outter_comman border rounded mb-4">
                <div class="row comman_header justify-content-between">
                  <div class="col">
                    <h2>Clinical Documentation</h2>
                  </div>
                </div>
                <form class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between">
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">
                      Clinician Feedback:Is the pair a good fit for Passport
                      Journeys currently? If no, what recommendations were made?
                    </label>
                    <textarea
                      class="form-control textarea_"
                      id="feedback"
                      name="feedback"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">Feedback from mother-daughter pair</label>
                    <textarea
                      class="form-control textarea_"
                      id="feedback_from_pair"
                      name="feedback_from_pair"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">Goals for treatment</label>
                    <textarea
                      class="form-control textarea_"
                      id="goals"
                      name="goals"
                      disabled={true}
                    />
                  </div>

                  <div class="form-group col-6 clinical_documentation">
                    <label for="">Safety Concerns</label>
                    <textarea
                      class="form-control textarea_"
                      id="safety_concerns"
                      name="safety_concerns"
                      disabled={true}
                    />
                  </div>
                  <div class="form-group col-6 clinical_documentation">
                    <label for="">Other pertinent information</label>
                    <textarea
                      class="form-control textarea_"
                      id="other_pertinent_information"
                      name="other_pertinent_information"
                      disabled={true}
                    />
                  </div>

                  <div class="form-group col-6  clinical_documentation">
                    <label for="">Plan</label>
                    <textarea
                      class="form-control textarea_"
                      id="plan"
                      name="plan"
                      disabled={true}
                    />
                  </div>

                  <div class="form-group col-6 mb-0 clinical_documentation mt-4">
                    <label for="">Session Attendee Names</label>
                    <input
                      type="text"
                      class="form-control"
                      id="attendees"
                      name="attendees"
                      disabled={true}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "VS" ? "show active" : ""
              }`}
              id="nav-VS"
              role="tabpanel"
              aria-labelledby="nav-VS-tab"
            >
              <div class="row mx-0 p-4">
                <div class="col-12 design_outter_comman border rounded mb-4">
                  <div class="row comman_header justify-content-between">
                    <div class="col">
                      <h2>Clinical Documentation</h2>
                    </div>
                  </div>

                  <form class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between">
                    <div class="form-group col-6 clinical_documentation">
                      <label for="">My Feedback</label>
                      <textarea
                        class="form-control textarea_"
                        id="feedback"
                        name="feedback"
                        disabled={true}
                      />
                    </div>
                    <div class="form-group col-6 clinical_documentation">
                      <label for="">Focus Of Session</label>
                      <textarea
                        class="form-control textarea_"
                        id="focus_of_session"
                        name="focus_of_session"
                        disabled={true}
                      />
                    </div>
                    <div class="form-group col-6 clinical_documentation">
                      <label for="">Progress Toward Goals</label>
                      <textarea
                        class="form-control textarea_"
                        id="progress_toward_goals"
                        name="progress_toward_goals"
                        disabled={true}
                      />
                    </div>
                    <div class="form-group col-6 clinical_documentation">
                      <label for="">Safety Concerns</label>
                      <textarea
                        class="form-control textarea_"
                        id="safety_concerns"
                        name="safety_concerns"
                        disabled={true}
                      />
                    </div>

                    <div class="form-group col-6  clinical_documentation">
                      <label for="">Plan</label>
                      <textarea
                        class="form-control textarea_"
                        id="plan"
                        name="plan"
                        disabled={true}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicianDocDummy;
