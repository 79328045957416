import React, { useEffect, useState } from "react";
import { assignJournal } from "../../apiServices/clinicianPanelHttpServices/loginHttpService/clinicianLoginHttpService";
import { useForm } from "react-hook-form";

function Journal({ sessionId, update }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const addJournal = async (data) => {
    data.sessionId = sessionId;
    data.journalId = "";
    const response = await assignJournal(data);
    if (!response.data.error) {
      console.log(data);

      await update();

      let defaultValue = {};
      defaultValue.title = "";
      defaultValue.topic = "";

      reset({ ...defaultValue });
    }
  };
  return (
    <>
      <form
        class="form-design py-4 px-3 help-support-form 
                  row align-items-end justify-content-between"
        onSubmit={handleSubmit(addJournal)}
        id="journal"
      >
        <div class="form-group col mb-0 ">
          <label for="">Title</label>
          <input
            type="text"
            class="form-control"
            id="title"
            name="title"
            {...register("title", {
              required: true,
            })}
          />
          {errors?.title && (
            <p className="form-error mt-2">This field is required</p>
          )}
        </div>

        <div class="form-group col mb-0">
          <label for="">Topic</label>
          <input
            type="text"
            class="form-control"
            id="topic"
            name="topic"
            {...register("topic", {
              required: true,
            })}
          />
          {errors?.topic && (
            <p className="form-error mt-2">This field is required</p>
          )}
        </div>

        <div class="form-group mb-0 col-auto mt-4 ">
          <button class="comman_btn" type="submit" id="journal">
            Save
          </button>
        </div>
      </form>
    </>
  );
}

export default Journal;
