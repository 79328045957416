import React, { useEffect, useRef, useState } from "react";import ClinicianSidebar from "../commonComponent/clinicianSidebar";
import ClinicianHeader from "../commonComponent/clinicianHeader";
import { Link } from "react-router-dom";
import socket from "../../apiServices/socket";
import {
  deleteChatt,
  getClinicianData,
} from "../../apiServices/clinicianPanelHttpServices/loginHttpService/clinicianLoginHttpService";
import moment from "moment";
import { toast } from "react-toastify";
import { io } from "socket.io-client";

function Chat() {
  const [clinicianData, setclinicianData] = useState("");
  const [chatList, setChatList] = useState([]);
  const [chatId, setChatId] = useState("");
  const [msgList, setMsgList] = useState([]);
  const [msg, setMsg] = useState("");
  const messageEl = useRef(null);
  const [search, setSearch] = useState("");

  
  useEffect(() => {
    getclinicianValue();
  }, []);

  useEffect(() => {
    const newSocket = io("https://passportjourneysapp.com:3003", {
      transports: ["websocket"],
    });

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    // client-side
    if (!socket.connected) {
      socket.on("connect", (data) => {
        console.log("socket_id", socket.id, data);
      });
    }
  }, []);

  useEffect(() => {
    if (messageEl.current) {
      const element = messageEl.current;
      element.scroll({
        top: element.scrollHeight,
        left: 0,
        behavior: "auto",
      });
    }
  }, [messageEl, msgList]);

  useEffect(() => {
    if (search) {
      const searchList = chatList.filter((item) => {
        return item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      });
      setChatList(searchList);
    } else {
      getclinicianValue();
    }
  }, [search]);

  const getclinicianValue = async () => {
    const { data } = await getClinicianData();
    if (!data.error) {
      setclinicianData(data.results.clinician);

      socket.emit("clinicians", data.results.clinician._id);
      const id = data.results.clinician._id;

      socket.on("chatList", (data) => {
        console.log(data);
        if (data[0]?.clinician === id) {
          setChatList(data);

          console.log(chatId);
          if (!chatId?._id) {
            // getMsg(data[0]);
          }
        } else {
          setChatList([]);
        }
      });
    }
  };
  const handleSendMessage = () => {
    if (!msg) {
      toast.error("Enter a Message");
      return;
    }
    const payload = {
      chatId: chatId._id,
      text: msg,
      senderId: clinicianData._id,
      sentBy: "Clinician",
    };
    setMsg("");
    socket.emit("sendMessage", payload);
  };

  const deleteChat = async () => {
    const { data } = await deleteChatt(chatId._id);
    if (!data.error) {
      getclinicianValue();
    }
  };

  const getMsg = (item, type) => {
    console.log(item);

    socket.emit("createRoomClinician", item._id);

    socket.on("messageList", (msgs) => {
      // console.log(msgs, type, item._id);

      if (msgs[0]?.chatId == item._id || type === 1) {
        console.log(msg, "jry");
        const groups = msgs.reduce((groups, game) => {
          const date = moment(new Date(game.createdAt)).format("LL");
          if (!groups[date]) {
            groups[date] = [];
          }
          if (!game.is_deleted_by_clinician) groups[date].push(game);
          return groups;
        }, {});

        // Edit: to add it in the array format instead
        const groupArrays = Object.keys(groups).map((date) => {
          return {
            date,
            messages: groups[date],
          };
        });

        setMsgList(groupArrays);
        console.log(groupArrays);
      } else if (!msgs.length) {
        setMsgList([]);
      }
    });
  };

  return (
    <>
      <div class="admin_main">
        <ClinicianSidebar />
        <div class="admin_main_inner">
          <ClinicianHeader />

          <div className="admin_panel_data height_adjust">
            <div className="message_chat">
              <div className="row">
                <div className="col-lg-4 m-lg-0 mb-md-4 mb-4">
                  <div className="message_left">
                    <div className="message_left_top">
                      <div className="dropdown all_message">
                        <span className="btn btn-secondary">Chats</span>
                      </div>
                      <div className="search_main">
                        <div className="search-container">
                          <i className="fa fa-search"></i>
                          <input
                            type="text"
                            placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="chat_user_show">
                      {chatList.map((item, index) => {
                        return (
                          <Link
                            to=""
                            className={
                              item._id === chatId._id
                                ? "row chatuser_box align-items-center active"
                                : "row chatuser_box align-items-center"
                            }
                            onClick={() => {
                              setChatId(item);
                              getMsg(item, 1);
                            }}
                          >
                            <div className="col-auto">
                              <div className="user_img">
                                <img
                                  src={
                                    item.profile_image
                                      ? item.profile_image
                                      : "../assets/img/hand (2).png"
                                  }
                                  alt=""
                                />
                                {/* <span /> */}
                              </div>
                            </div>
                            <div className="col chatuser_name ps-0">
                              <h2>{item.name}</h2>
                              <span>{item.lastMessage}</span>
                            </div>
                            <div className="col-auto last_part text-end">
                              <div className="msg-time">
                                {moment(item.timestamp).format("hh:mm")}
                              </div>
                              {/* <div className="msg-count ms-auto">2</div> */}
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="message_right">
                    <div className="chat_main">
                      <div className="chat_header row align-items-center mx-0">
                        <div className="col-auto">
                          <Link
                            className="chat_header_profile"
                            to={`/clinician/user-details/${chatId.mother}`}
                          >
                            <img
                              src={
                                chatId.profile_image
                                  ? chatId.profile_image
                                  : "../assets/img/hand (2).png"
                              }
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="col px-0">
                          <div className="chat_header_text">
                            <a href="javascript:;">{chatId.name}</a>
                          </div>
                        </div>

                        <div class="col-auto d-flex align-items-center">
                          <div class="dropdown chat_dropdown">
                            <a
                              class="btn btn-secondary"
                              href="#"
                              role="button"
                              id="dropdownMenuLink1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="../assets/img/bars.png" alt="" />
                            </a>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuLink1"
                            >
                              <Link to="" onClick={() => deleteChat()}>
                                Delete Chat
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="chat_mid" ref={messageEl}>
                        {msgList.map((item, index) => {
                          return (
                            <div className="row">
                              <div className="col-12 timer_label mb-3">
                                <div className="row mx-0">
                                  <div className="col-12 text-center">
                                    <span>{item.date}</span>
                                  </div>
                                </div>
                              </div>

                              {item.messages.map((item1, index) => {
                                return item1.sentBy !== "Clinician" ? (
                                  <div className="col-12 user_chat mb-md-3 mb-4">
                                    <div className="row mx-0 justify-content-start">
                                      <div className="col-auto">
                                        <a
                                          href="javascript:;"
                                          className="chat_comman_pix"
                                        >
                                          <img
                                            src={
                                              item1.profile_image
                                                ? item1.profile_image
                                                : "../assets/img/hand (2).png"
                                            }
                                            alt=""
                                          />
                                        </a>
                                      </div>
                                      <div className="col-5 ps-0">
                                        <div className="chat_comman_content">
                                          <span>{item1?.user?.name}</span>
                                          <p>{item1.text}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col-12 mine_chat mb-md-3 mb-4">
                                    <div className="row mx-0 justify-content-end">
                                      <div className="col-5 pe-0">
                                        <div className="chat_comman_content text-end">
                                          <span>Me</span>
                                          <p>{item1.text}</p>
                                        </div>
                                      </div>
                                      <div className="col-auto">
                                        <a
                                          href="javascript:;"
                                          className="chat_comman_pix"
                                        >
                                          <img
                                            src={
                                              item1.profile_image
                                                ? item1.profile_image
                                                : "../assets/img/hand (2).png"
                                            }
                                            alt=""
                                          />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                      <div className="chat_bottombar">
                        <div className="bottombar">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Send message..."
                            onChange={(e) => setMsg(e.target.value)}
                            value={msg}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault();
                                handleSendMessage();
                              }
                            }}
                          />

                          <Link
                            className="send_btn"
                            to=""
                            onClick={() => handleSendMessage()}
                          >
                            <img src="../assets/img/send.png" alt="" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chat;
